import { OpportunityEntity } from 'types/opportunity-types';

export const initialOpportunity: OpportunityEntity = {
  id: '',
  name: '',
  date_entered: '',
  date_modified: null,
  modified_user_id: '',
  created_by: '',
  description: '',
  deleted: false,
  assigned_user_id: '',
  opportunity_type: '',
  campaign_id: null,
  lead_source: null,
  amount: null,
  amount_usdollar: null,
  currency_id: '-99',
  date_closed: '',
  next_step: null,
  sales_stage: null,
  probability: 0,
  cancel_reason: '',
  reject_reason: '',
  documentation_delivered: null,
  emd_requested_confirmed: '',
  approved_dd_release: null,
  counter_offer: '',
  sale_avm_actual: 0,
  rent_avm_actual: 0,
  sold_price: '',
  sale_date: '',
  short_sale_seller_accept_date: '',
  roof_type: '',
  edit_offer_version: 0,
  qb_invoice_no: null,
  heating_system: null,
  cooling_system: null,
  property_access_contact_phone: null,
  property_access_contact_name: null,
  property_access_contact_email: null,
  property_access_comments: null,
  offer_submit_by_date: '',
  offer_submit_type: null,
  bid_id: null,
  bid_url: null,
  rep_name: null,
  rep_email: null,
  rep_phone: null,
  rep_company: null,
  commision_pending: 0,
  commision_reduction: '',
  final_commission: 0,
  commission_pay_type: 'mail',
  contract_terminated_date: null,
  visit_evaluator_type: null,
  evaluator_date: null,
  visit_date: null,
  visit_earliest_time: null,
  visit_latest_time: null,
  evaluator_comments: null,
  visit_comments: null,
  inspect_date: null,
  inspect_comments: null,
  full_address: '',
  simba_unique_id: null,
  mo_photos_required: 0,
  mo_photos_date: null,
  mo_photos_source: null,
  product_type: null,
  net_offer: null,
  is_sqs_opendoor: 0,
  reject_date: '',
  offer_lost_date: '',
  initial_commission_percentage: 0,
  final_commission_percentage: 0,
  initial_commission_amount: 0,
  cda_amount_2: '',
  cda_amount_3: '',
  cda_pay_to_2: null,
  cda_pay_to_3: null,
  visit_agree: 1,
  offer_initialized_at: '',
  offer_finalized_at: null,
  entera_opportunity_id: '',
  escrow_percent: null,
  entera_customer_id: 30,
  closing_attorney: null,
  closing_attorney_contract: null,
  closing_attorney_email: null,
  closing_attorney_phone: null,
  closing_attorney_address: null,
  msa_id_1: null,
  primary_tr_id: null,
  manager_id: null,
  account_id_1: null,
  is_sqs_price_status: 0,
  letter_of_intent: false,
  save_for_singnature_by: null,
  offer_submitted_by: null,
  congrats_letter_sent_by: null,
  congrats_letter_sent_date: null,
  access_requested_by: '',
  access_requested_date: '',
  school_district: null,
  reduction_type: '',
  commision_reduction2: '',
  commision_reduction3: '',
  hud_commission: '',
  commissions_flags: null,
  commission_reduction_type2: '',
  commission_reduction_type3: '',
  total_commission_reductions: 0,
  file_location: 'not_found',
  addendum_no: '',
  addendum_notes: '',
  restrictions_notes: '',
  has_leasing_restrictions: '',
  offer_documents_status: null,
  commission_received: null,
  offer_documents_created_at: null,
  escalation_clause_amount: null,
  customer_reservation_status: null,
  contract_termination_reasons: '',
  customer_accepted_status_verified: null,
  parties_listing_agent_id: null,
  seller_offer_response: '',
  next_seller_contact_date: null,
  offer_price_at_acceptance: '',
  parties_co_listing_agent_id: null,
  inclusion: '',
  exclusion: '',
  internal_termination_feedback: '',
  hoa_name: null,
  roof_age: null,
  hoa_url: null,
  hvac_age: null,
  submitting_user_code: null,
  behalf_of_user_code: null,
  market_preference_id: '',
  rental_amount: '',
  lease_type: '',
  deposit_amount: 0,
  lease_end_date: '',
  nego_at_offer_accept: null,
  nego_at_closing: null,
  nego_at_offer_sent: null,
  special_instructions: null,
  license_supervisor_license_number: null,
  licensed_supervisor_of_listing_associate: null,
  additional_cancellation_reason: null,
  original_close_of_escrow_date: '',
  seller_ownership_representation: null,
  rpd_received: 0,
  mog_received: false,
  water_and_sewer: null,
  offer_cancelled_date: '',
  sellers_disclosure_received: 0,
  settlement_date: null,
  foundation_type: null,
  no_diligence_period: false,
  key_location: null,
  tenant_phone: null,
  tenant_email: null,
  tenant_name: null,
  parties_tenant_id: null,
  parties_transaction_coordinator_id: null,
  is_inspection_contingencies_waived: null,
  disable_inspection_emails_for_waived_inspections: 'no',
  entera_sourced_off_market: false,
  commission_contribution_at_offer_accept: null,
  contract_review_complete: 0,
  active_primary_negotiator_user_id: '',
  street_name: '',
  has_post_occupancy: '',
  pre_close_notification_to_recipient: null,
  pre_close_notification_cc_recipient: null,
  broker_rep_firm: null,
  broker_rep_firm_license_number: null,
  broker_rep_mlsid: null,
  broker_rep_agent_license_number: null,
  broker_rep_agent_email: null,
  executed_contract_email_to: null,
  executed_contract_email_cc: null,
  executed_contract_email_bcc: null,
  buyer_assignability: null,
  mo_photos_link: null,
  access_notes: null,
  primary_tc_at_closing: null,
  primary_logistics_at_closing: null,
  primary_tc_at_diligence: null,
  primary_tc_at_sale_pending: null,
  primary_logistics_at_diligence: null,
  primary_logistics_at_sale_pending: null,
  backwards_status_move_reason: null,
  parties_seller_id: '',
  parties_seller_representative_id: '',
  parties_lead_source_id: '',
  parties_lead_owner_id: '',
  parties_buyer_representative_id: '',
  parties_intended_buyer_id: '',
  parties_uploaded_by_id: '',
  earnest_recovered_by_buyer: '',
  earnest_recovery_amount: '',
  earnest_paid_by_entera: '',
  earnest_paid_by_entera_amount: '',
  new_construction_no_tax_id: 0,
  municipal_inspection_types: [],
  municipal_inspection_notes: '',
  mailbox_location: null,
  reject_note: '',
  competing_offer_count: '',
  competing_offer_highest_price: '',
  buyer_offer_key: null,
  cancel_note: '',
  cancel_request_received_date: '',
  leaseback_3_sent_at: null,
  leaseback_1_sent_at: null,
  leaseback_3_sent_by: null,
  leaseback_1_sent_by: null,
  opportunity_counteroffer_id: '',
  primary_tc: null,
  primary_logistics: null,
  earnest_amount_verified: null,
  option_fee_verified: null,
  contract_received_date: '',
  solar_panel_lease_date_expiration: '',
  other_counter_offer_terms: '',
  solar_panel_payoff_amount: '',
  solar_panel_leased_or_owned: 'no',
  hoa_addendum_received: '',
  due_diligence_days_confirm: null,
  due_diligence_fee: null,
  preferred_title: null,
  lockbox_code: null,
  access_type: null,
  seller_id: null,
  parties_escrow_company_id: null,
  reason_description: null,
  transaction_notes: null,
  wire_fee_amount: null,
  data_source: '',
  dd_fee_payment_tracking_number: '',
  offer_strategy: null,
  extension_request_reason: '',
  benefitting_negotiator_id: '',
  benefitting_negotiator_changed_at: '',
  benefitting_negotiator_changed_by_id: '',
  benefitting_negotiator_is_locked: false,
  forecasted_dd_end_date: '',
  forecasted_close_date: '',
  gate_code: null,
  retrade_reason: '',
  buyer_requested_retrade_amount: '',
  negotiator_sent_retrade_amount: '',
  actual_retrade_amount: '',
  request_retrade_amount: '',
  entera_contribution: '',
  entera_contribution_amount: '',
  pending_extension: 'no',
  pending_retrade: 'no',
  approved_to_close: 'no',
  deprecated_buyer_name_hud_id: null,
  buyer_contract_name_at_offer_sent_id: '',
  buyer_contract_name_at_diligence_start_id: '',
  buyer_contract_name_at_closing_id: '',
  cancellation_reason_subcategory: '',
  termination_reason_subcategory: '',
  lease_agreement_received: 0,
  lowest_acceptable_price: null,
  offer_response_details: null,
  offer_grade: null,
  account_buyer_id: '',
  entera_market_id: 10,
  buyer_name_prelim_hud_id: null,
  expected_rehab_tier: null,
  ip_address: '10.10.5.244',
  request_id: '',
  portfolio_id: null,
  portfolio_name: null,
  id_c: '',
  jjwg_maps_lng_c: 0,
  jjwg_maps_lat_c: 0,
  jjwg_maps_geocode_status_c: null,
  jjwg_maps_address_c: null,
  glt_property_id_c: '',
  mls_c: '',
  tax_id_c: '',
  buyer_contract_name_c: '',
  customer_name_c: '',
  property_address_city_c: '',
  property_address_postalcode_c: '',
  property_address_c: '',
  msa_c: 'atlanta',
  subdivision_c: '',
  legal_description_c: '',
  bed_c: '',
  bath_c: '',
  year_built_c: '',
  sq_ft_c: '',
  lot_size_c: '',
  pool_c: '',
  offer_date_c: '',
  offer_price_c: '',
  max_offer_price_c: '',
  financing_c: 'no',
  financing_amount_c: '',
  balance_to_close_c: 0,
  option_amount_c: '',
  option_inspection_period_c: '',
  option_delivery_instructions_c: null,
  earnest_amount_c: '',
  earnest_delivery_instruction_c: null,
  additional_terms_comments_c: '',
  vacant_c: '',
  opportunity_status_c: '',
  showing_information_instruct_c: '',
  title_company_name_c: null,
  title_company_address_c: null,
  title_company_contact_c: null,
  title_company_phone_c: null,
  title_company_email_c: null,
  counter_offer_price_c: '',
  contract_price_c: '',
  negotiation_notes_c: '',
  remove_glt_notes_c: '',
  list_date_c: '',
  mls_status_c: '',
  list_price_c: '',
  offer_to_list_c: 0,
  escrow_company_fax_c: null,
  occupancy_status_c: '',
  type_of_financing_c: '',
  initial_interest_rate_c: 0,
  initial_financing_term_years_c: 0,
  buyers_attorney_name_c: '',
  buyer_notice_address_c: '',
  buyer_phone_c: '',
  buyer_email_c: '',
  legal_subdivision_c: '',
  offer_expiration_date_c: '',
  option_fee_status_c: '',
  earnest_money_status_c: '',
  option_fee_delivery_date_c: '',
  subdivision_section_c: '',
  property_lot_number_c: '',
  property_block_number_c: '',
  property_excludes_c: null,
  type_c: 'buyer',
  buyer_commission_c: '0',
  buyer_bonus_c: '',
  listing_commission_c: null,
  listing_bonus_c: '',
  close_date_c: '',
  equipment_c: '',
  public_remarks_c: '',
  realtor_remarks_c: '',
  opportunity_rehab_c: '',
  projected_rehab_c: null,
  actual_rehab_c: '',
  county_c: '',
  chat_text_c: null,
  photo_c: '',
  buyer_contract_name_2_c: null,
  title_payor_c: 'seller',
  survey_requested_c: 'no',
  land_lots_c: '',
  district_number_c: '',
  phase_number_c: '',
  plat_book_number_c: '',
  plat_page_number_c: '',
  deed_book_number_c: '',
  deed_book_page_c: '',
  warranty_required_c: '',
  homeowner_association_c: '',
  contract_execution_date_c: '',
  lease_back_allowed_c: '',
  loan_percent_c: '',
  seller_closing_costs_c: '',
  seller_closing_support_c: null,
  appraisal_required_c: '',
  unit_number_c: '',
  market_signer_name_c: '',
  market_signer_initials_c: '',
  market_signer_name_2_c: '',
  market_signer_initials_2_c: '',
  property_address_state_c: '',
  due_diligence_end_c: '',
  earnest_money_due_c: '',
  em_delivery_date_c: '',
  remove_glt_mls_id_c: '',
  basement_c: '',
  option_days_type_c: '',
  option_period_days_c: '',
  listing_source_c: 'mls',
  listing_type_c: 'mls',
  garage_c: '',
  earnest_money_due_date_c: null,
  additional_access_information_c: '',
  frequency_closing_status_c: '',
  flood_zone_c: '',
  census_tract_c: '',
  hoa_fees_monthly_c: null,
  rent_avm_c: null,
  calculated_tax_c: null,
  sale_avm_c: '',
  property_type_c: '',
  customer_brokerage_c: '',
  customer_broker_name_c: '',
  wire_request_name_c: '',
  benificiary_name_c: '',
  beneficiary_address_c: null,
  beneficiary_city_c: '',
  beneficiary_account_c: '',
  routing_number_c: '',
  wire_information_c: '',
  wire_purpose_c: '',
  wire_status_c: '',
  initial_offer_price_c: '',
  additional_earnest_money_c: null,
  contract_amendment_date_c: null,
  purchase_price_c: 0,
  entera_property_id_c: '',
  due_diligence_start_c: null,
  revised_list_price_c: null,
  list_price_updated_date_c: null,
  mls_updated_timestamp_c: null,
  offer_to_market_value_percentage_c: 0,
  updated_at_c: '',
  initial_due_diligence_end: '',
  account: {
    id: '',
    name: ''
  },
  market: {
    id: '',
    name: ''
  },
  market_preference: {
    id: '',
    name: '',
    sqs_status_trigger: ''
  },
  opportunity_service_provider: {
    id: '',
    opportunity_id: '',
    service_provider_id: null,
    service_type: null,
    service_amount: '',
    service_date: ''
  },
  hoa_management_company: {
    id: '',
    opportunity_id: '',
    name: '',
    company_phone: '',
    contact_name: '',
    contact_phone: '',
    contact_address: '',
    is_self_managed: 0,
    contact_email: ''
  },
  property_utility_provider: {
    id: '',
    opportunity_id: '',
    electric_provider: '',
    gas_provider: '',
    is_electric: 0,
    water_well_provider: '',
    is_well: 0,
    sewer_provider: '',
    is_septic: 0,
    trash_provider: ''
  }
};
