
enum userTimeZone {
  'Pacific/Midway' = 'Pacific/Midway (GMT-11:00)',
  'Pacific/Niue' = 'Pacific/Niue (GMT-11:00)',
  'Pacific/Pago_Pago' = 'Pacific/Pago Pago (GMT-11:00)',
  'Pacific/Honolulu' = 'Pacific/Honolulu (GMT-10:00)',
  'Pacific/Rarotonga' = 'Pacific/Rarotonga (GMT-10:00)',
  'Pacific/Tahiti' = 'Pacific/Tahiti (GMT-10:00)',
  'Pacific/Marquesas' = 'Pacific/Marquesas (GMT-9:30)',
  'America/Adak' = 'America/Adak (GMT-9:00)',
  'Pacific/Gambier' = 'Pacific/Gambier (GMT-9:00)',
  'America/Anchorage' = 'America/Anchorage (GMT-8:00)',
  'America/Juneau' = 'America/Juneau (GMT-8:00)',
  'America/Metlakatla' = 'America/Metlakatla (GMT-8:00)',
  'America/Nome' = 'America/Nome (GMT-8:00)',
  'America/Sitka' = 'America/Sitka (GMT-8:00)',
  'America/Yakutat' = 'America/Yakutat (GMT-8:00)',
  'Pacific/Pitcairn' = 'Pacific/Pitcairn (GMT-8:00)',
  'America/Creston' = 'America/Creston (GMT-7:00)',
  'America/Dawson' = 'America/Dawson (GMT-7:00)',
  'America/Dawson_Creek' = 'America/Dawson Creek (GMT-7:00)',
  'America/Fort_Nelson' = 'America/Fort Nelson (GMT-7:00)',
  'America/Hermosillo' = 'America/Hermosillo (GMT-7:00)',
  'America/Los_Angeles' = 'America/Los Angeles (GMT-7:00)',
  'America/Mazatlan' = 'America/Mazatlan (GMT-7:00)',
  'America/Phoenix' = 'America/Phoenix (GMT-7:00)',
  'America/Tijuana' = 'America/Tijuana (GMT-7:00)',
  'America/Vancouver' = 'America/Vancouver (GMT-7:00)',
  'America/Whitehorse' = 'America/Whitehorse (GMT-7:00)',
  'America/Bahia_Banderas' = 'America/Bahia Banderas (GMT-6:00)',
  'America/Belize' = 'America/Belize (GMT-6:00)',
  'America/Boise' = 'America/Boise (GMT-6:00)',
  'America/Cambridge_Bay' = 'America/Cambridge Bay (GMT-6:00)',
  'America/Chihuahua' = 'America/Chihuahua (GMT-6:00)',
  'America/Ciudad_Juarez' = 'America/Ciudad Juarez (GMT-6:00)',
  'America/Costa_Rica' = 'America/Costa Rica (GMT-6:00)',
  'America/Denver' = 'America/Denver (GMT-6:00)',
  'America/Edmonton' = 'America/Edmonton (GMT-6:00)',
  'America/El_Salvador' = 'America/El Salvador (GMT-6:00)',
  'America/Guatemala' = 'America/Guatemala (GMT-6:00)',
  'America/Inuvik' = 'America/Inuvik (GMT-6:00)',
  'America/Managua' = 'America/Managua (GMT-6:00)',
  'America/Merida' = 'America/Merida (GMT-6:00)',
  'America/Mexico_City' = 'America/Mexico City (GMT-6:00)',
  'America/Monterrey' = 'America/Monterrey (GMT-6:00)',
  'America/Regina' = 'America/Regina (GMT-6:00)',
  'America/Swift_Current' = 'America/Swift Current (GMT-6:00)',
  'America/Tegucigalpa' = 'America/Tegucigalpa (GMT-6:00)',
  'America/Yellowknife' = 'America/Yellowknife (GMT-6:00)',
  'Pacific/Galapagos' = 'Pacific/Galapagos (GMT-6:00)',
  'America/Atikokan' = 'America/Atikokan (GMT-5:00)',
  'America/Bogota' = 'America/Bogota (GMT-5:00)',
  'America/Cancun' = 'America/Cancun (GMT-5:00)',
  'America/Cayman' = 'America/Cayman (GMT-5:00)',
  'America/Chicago' = 'America/Chicago (GMT-5:00)',
  'America/Eirunepe' = 'America/Eirunepe (GMT-5:00)',
  'America/Guayaquil' = 'America/Guayaquil (GMT-5:00)',
  'America/Indiana/Knox' = 'America/Indiana/Knox (GMT-5:00)',
  'America/Indiana/Tell_City' = 'America/Indiana/Tell City (GMT-5:00)',
  'America/Jamaica' = 'America/Jamaica (GMT-5:00)',
  'America/Lima' = 'America/Lima (GMT-5:00)',
  'America/Matamoros' = 'America/Matamoros (GMT-5:00)',
  'America/Menominee' = 'America/Menominee (GMT-5:00)',
  'America/North_Dakota/Beulah' = 'America/North Dakota/Beulah (GMT-5:00)',
  'America/North_Dakota/Center' = 'America/North Dakota/Center (GMT-5:00)',
  'America/North_Dakota/New_Salem' = 'America/North Dakota/New Salem (GMT-5:00)',
  'America/Ojinaga' = 'America/Ojinaga (GMT-5:00)',
  'America/Panama' = 'America/Panama (GMT-5:00)',
  'America/Rankin_Inlet' = 'America/Rankin Inlet (GMT-5:00)',
  'America/Resolute' = 'America/Resolute (GMT-5:00)',
  'America/Rio_Branco' = 'America/Rio Branco (GMT-5:00)',
  'America/Winnipeg' = 'America/Winnipeg (GMT-5:00)',
  'Pacific/Easter' = 'Pacific/Easter (GMT-5:00)',
  'America/Anguilla' = 'America/Anguilla (GMT-4:00)',
  'America/Antigua' = 'America/Antigua (GMT-4:00)',
  'America/Aruba' = 'America/Aruba (GMT-4:00)',
  'America/Asuncion' = 'America/Asuncion (GMT-4:00)',
  'America/Barbados' = 'America/Barbados (GMT-4:00)',
  'America/Blanc-Sablon' = 'America/Blanc-Sablon (GMT-4:00)',
  'America/Boa_Vista' = 'America/Boa Vista (GMT-4:00)',
  'America/Campo_Grande' = 'America/Campo Grande (GMT-4:00)',
  'America/Caracas' = 'America/Caracas (GMT-4:00)',
  'America/Cuiaba' = 'America/Cuiaba (GMT-4:00)',
  'America/Curacao' = 'America/Curacao (GMT-4:00)',
  'America/Detroit' = 'America/Detroit (GMT-4:00)',
  'America/Dominica' = 'America/Dominica (GMT-4:00)',
  'America/Grand_Turk' = 'America/Grand Turk (GMT-4:00)',
  'America/Grenada' = 'America/Grenada (GMT-4:00)',
  'America/Guadeloupe' = 'America/Guadeloupe (GMT-4:00)',
  'America/Guyana' = 'America/Guyana (GMT-4:00)',
  'America/Havana' = 'America/Havana (GMT-4:00)',
  'America/Indiana/Indianapolis' = 'America/Indiana/Indianapolis (GMT-4:00)',
  'America/Indiana/Marengo' = 'America/Indiana/Marengo (GMT-4:00)',
  'America/Indiana/Petersburg' = 'America/Indiana/Petersburg (GMT-4:00)',
  'America/Indiana/Vevay' = 'America/Indiana/Vevay (GMT-4:00)',
  'America/Indiana/Vincennes' = 'America/Indiana/Vincennes (GMT-4:00)',
  'America/Indiana/Winamac' = 'America/Indiana/Winamac (GMT-4:00)',
  'America/Iqaluit' = 'America/Iqaluit (GMT-4:00)',
  'America/Kentucky/Louisville' = 'America/Kentucky/Louisville (GMT-4:00)',
  'America/Kentucky/Monticello' = 'America/Kentucky/Monticello (GMT-4:00)',
  'America/Kralendijk' = 'America/Kralendijk (GMT-4:00)',
  'America/La_Paz' = 'America/La Paz (GMT-4:00)',
  'America/Lower_Princes' = 'America/Lower Princes (GMT-4:00)',
  'America/Manaus' = 'America/Manaus (GMT-4:00)',
  'America/Marigot' = 'America/Marigot (GMT-4:00)',
  'America/Martinique' = 'America/Martinique (GMT-4:00)',
  'America/Montserrat' = 'America/Montserrat (GMT-4:00)',
  'America/Nassau' = 'America/Nassau (GMT-4:00)',
  'America/New_York' = 'America/New York (GMT-4:00)',
  'America/Port-au-Prince' = 'America/Port-au-Prince (GMT-4:00)',
  'America/Port_of_Spain' = 'America/Port-of-Spain (GMT-4:00)',
  'America/Porto_Velho' = 'America/Porto Velho (GMT-4:00)',
  'America/Puerto_Rico' = 'America/Puerto Rico (GMT-4:00)',
  'America/Santo_Domingo' = 'America/Santo Domingo (GMT-4:00)',
  'America/St_Barthelemy' = 'America/St Barthelemy (GMT-4:00)',
  'America/St_Kitts' = 'America/St Kitts (GMT-4:00)',
  'America/St_Lucia' = 'America/St Lucia (GMT-4:00)',
  'America/St_Thomas' = 'America/St. Thomas (GMT-4:00)',
  'America/St_Vincent' = 'America/St. Vincent (GMT-4:00)',
  'America/Toronto' = 'America/Toronto (GMT-4:00)',
  'America/Tortola' = 'America/Tortola (GMT-4:00)',
  'America/Araguaina' = 'America/Araguaina (GMT-3:00)',
  'America/Argentina/Buenos_Aires' = 'America/Argentina/Buenos Aires (GMT-3:00)',
  'America/Argentina/Catamarca' = 'America/Argentina/Catamarca (GMT-3:00)',
  'America/Argentina/Cordoba' = 'America/Argentina/Cordoba (GMT-3:00)',
  'America/Argentina/Jujuy' = 'America/Argentina/Jujuy (GMT-3:00)',
  'America/Argentina/La_Rioja' = 'America/Argentina/La Rioja (GMT-3:00)',
  'America/Argentina/Mendoza' = 'America/Argentina/Mendoza (GMT-3:00)',
  'America/Argentina/Rio_Gallegos' = 'America/Argentina/Rio Gallegos (GMT-3:00)',
  'America/Argentina/Salta' = 'America/Argentina/Salta (GMT-3:00)',
  'America/Argentina/San_Juan' = 'America/Argentina/San Juan (GMT-3:00)',
  'America/Argentina/San_Luis' = 'America/Argentina/San Luis (GMT-3:00)',
  'America/Argentina/Tucuman' = 'America/Argentina/Tucuman (GMT-3:00)',
  'America/Argentina/Ushuaia' = 'America/Argentina/Ushuaia (GMT-3:00)',
  'America/Bahia' = 'America/Bahia (GMT-3:00)',
  'America/Belem' = 'America/Belem (GMT-3:00)',
  'America/Cayenne' = 'America/Cayenne (GMT-3:00)',
  'America/Fortaleza' = 'America/Fortaleza (GMT-3:00)',
  'America/Glace_Bay' = 'America/Glace Bay (GMT-3:00)',
  'America/Goose_Bay' = 'America/Goose Bay (GMT-3:00)',
  'America/Halifax' = 'America/Halifax (GMT-3:00)',
  'America/Maceio' = 'America/Maceio (GMT-3:00)',
  'America/Moncton' = 'America/Moncton (GMT-3:00)',
  'America/Montevideo' = 'America/Montevideo (GMT-3:00)',
  'America/Paramaribo' = 'America/Paramaribo (GMT-3:00)',
  'America/Punta_Arenas' = 'America/Punta Arenas (GMT-3:00)',
  'America/Recife' = 'America/Recife (GMT-3:00)',
  'America/Santarem' = 'America/Santarem (GMT-3:00)',
  'America/Santiago' = 'America/Santiago (GMT-3:00)',
  'America/Sao_Paulo' = 'America/Sao Paulo (GMT-3:00)',
  'America/Thule' = 'America/Thule (GMT-3:00)',
  'Antarctica/Palmer' = 'Antarctica/Palmer (GMT-3:00)',
  'Antarctica/Rothera' = 'Antarctica/Rothera (GMT-3:00)',
  'Atlantic/Bermuda' = 'Atlantic/Bermuda (GMT-3:00)',
  'Atlantic/Stanley' = 'Atlantic/Stanley (GMT-3:00)',
  'America/St_Johns' = 'America/St. Johns (GMT-2:30)',
  'America/Miquelon' = 'America/Miquelon (GMT-2:00)',
  'America/Noronha' = 'America/Noronha (GMT-2:00)',
  'America/Nuuk' = 'America/Nuuk (GMT-2:00)',
  'Atlantic/South_Georgia' = 'Atlantic/South Georgia (GMT-2:00)',
  'Atlantic/Cape_Verde' = 'Atlantic/Cape Verde (GMT-1:00)',
  'Africa/Abidjan' = 'Africa/Abidjan (GMT+0:00)',
  'Africa/Accra' = 'Africa/Accra (GMT+0:00)',
  'Africa/Bamako' = 'Africa/Bamako (GMT+0:00)',
  'Africa/Banjul' = 'Africa/Banjul (GMT+0:00)',
  'Africa/Bissau' = 'Africa/Bissau (GMT+0:00)',
  'Africa/Casablanca' = 'Africa/Casablanca (GMT+0:00)',
  'Africa/Conakry' = 'Africa/Conakry (GMT+0:00)',
  'Africa/Dakar' = 'Africa/Dakar (GMT+0:00)',
  'Africa/El_Aaiun' = 'Africa/El Aaiun (GMT+0:00)',
  'Africa/Freetown' = 'Africa/Freetown (GMT+0:00)',
  'Africa/Lome' = 'Africa/Lome (GMT+0:00)',
  'Africa/Monrovia' = 'Africa/Monrovia (GMT+0:00)',
  'Africa/Nouakchott' = 'Africa/Nouakchott (GMT+0:00)',
  'Africa/Ouagadougou' = 'Africa/Ouagadougou (GMT+0:00)',
  'Africa/Sao_Tome' = 'Africa/Sao Tome (GMT+0:00)',
  'America/Danmarkshavn' = 'America/Danmarkshavn (GMT+0:00)',
  'America/Scoresbysund' = 'America/Scoresbysund (GMT+0:00)',
  'Atlantic/Azores' = 'Atlantic/Azores (GMT+0:00)',
  'Atlantic/Reykjavik' = 'Atlantic/Reykjavik (GMT+0:00)',
  'Atlantic/St_Helena' = 'Atlantic/St. Helena (GMT+0:00)',
  UTC = 'UTC (GMT+0:00)',
  'Africa/Algiers' = 'Africa/Algiers (GMT+1:00)',
  'Africa/Bangui' = 'Africa/Bangui (GMT+1:00)',
  'Africa/Brazzaville' = 'Africa/Brazzaville (GMT+1:00)',
  'Africa/Douala' = 'Africa/Douala (GMT+1:00)',
  'Africa/Kinshasa' = 'Africa/Kinshasa (GMT+1:00)',
  'Africa/Lagos' = 'Africa/Lagos (GMT+1:00)',
  'Africa/Libreville' = 'Africa/Libreville (GMT+1:00)',
  'Africa/Luanda' = 'Africa/Luanda (GMT+1:00)',
  'Africa/Malabo' = 'Africa/Malabo (GMT+1:00)',
  'Africa/Ndjamena' = 'Africa/Ndjamena (GMT+1:00)',
  'Africa/Niamey' = 'Africa/Niamey (GMT+1:00)',
  'Africa/Porto-Novo' = 'Africa/Porto-Novo (GMT+1:00)',
  'Africa/Tunis' = 'Africa/Tunis (GMT+1:00)',
  'Atlantic/Canary' = 'Atlantic/Canary (GMT+1:00)',
  'Atlantic/Faroe' = 'Atlantic/Faroe (GMT+1:00)',
  'Atlantic/Madeira' = 'Atlantic/Madeira (GMT+1:00)',
  'Europe/Dublin' = 'Europe/Dublin (GMT+1:00)',
  'Europe/Guernsey' = 'Europe/Guernsey (GMT+1:00)',
  'Europe/Isle_of_Man' = 'Europe/Isle of Man (GMT+1:00)',
  'Europe/Jersey' = 'Europe/Jersey (GMT+1:00)',
  'Europe/Lisbon' = 'Europe/Lisbon (GMT+1:00)',
  'Europe/London' = 'Europe/London (GMT+1:00)',
  'Africa/Blantyre' = 'Africa/Blantyre (GMT+2:00)',
  'Africa/Bujumbura' = 'Africa/Bujumbura (GMT+2:00)',
  'Africa/Cairo' = 'Africa/Cairo (GMT+2:00)',
  'Africa/Ceuta' = 'Africa/Ceuta (GMT+2:00)',
  'Africa/Gaborone' = 'Africa/Gaborone (GMT+2:00)',
  'Africa/Harare' = 'Africa/Harare (GMT+2:00)',
  'Africa/Johannesburg' = 'Africa/Johannesburg (GMT+2:00)',
  'Africa/Juba' = 'Africa/Juba (GMT+2:00)',
  'Africa/Khartoum' = 'Africa/Khartoum (GMT+2:00)',
  'Africa/Kigali' = 'Africa/Kigali (GMT+2:00)',
  'Africa/Lubumbashi' = 'Africa/Lubumbashi (GMT+2:00)',
  'Africa/Lusaka' = 'Africa/Lusaka (GMT+2:00)',
  'Africa/Maputo' = 'Africa/Maputo (GMT+2:00)',
  'Africa/Maseru' = 'Africa/Maseru (GMT+2:00)',
  'Africa/Mbabane' = 'Africa/Mbabane (GMT+2:00)',
  'Africa/Tripoli' = 'Africa/Tripoli (GMT+2:00)',
  'Africa/Windhoek' = 'Africa/Windhoek (GMT+2:00)',
  'Antarctica/Troll' = 'Antarctica/Troll (GMT+2:00)',
  'Arctic/Longyearbyen' = 'Arctic/Longyearbyen (GMT+2:00)',
  'Europe/Amsterdam' = 'Europe/Amsterdam (GMT+2:00)',
  'Europe/Andorra' = 'Europe/Andorra (GMT+2:00)',
  'Europe/Belgrade' = 'Europe/Belgrade (GMT+2:00)',
  'Europe/Berlin' = 'Europe/Berlin (GMT+2:00)',
  'Europe/Bratislava' = 'Europe/Bratislava (GMT+2:00)',
  'Europe/Brussels' = 'Europe/Brussels (GMT+2:00)',
  'Europe/Budapest' = 'Europe/Budapest (GMT+2:00)',
  'Europe/Busingen' = 'Europe/Busingen (GMT+2:00)',
  'Europe/Copenhagen' = 'Europe/Copenhagen (GMT+2:00)',
  'Europe/Gibraltar' = 'Europe/Gibraltar (GMT+2:00)',
  'Europe/Kaliningrad' = 'Europe/Kaliningrad (GMT+2:00)',
  'Europe/Ljubljana' = 'Europe/Ljubljana (GMT+2:00)',
  'Europe/Luxembourg' = 'Europe/Luxembourg (GMT+2:00)',
  'Europe/Madrid' = 'Europe/Madrid (GMT+2:00)',
  'Europe/Malta' = 'Europe/Malta (GMT+2:00)',
  'Europe/Monaco' = 'Europe/Monaco (GMT+2:00)',
  'Europe/Oslo' = 'Europe/Oslo (GMT+2:00)',
  'Europe/Paris' = 'Europe/Paris (GMT+2:00)',
  'Europe/Podgorica' = 'Europe/Podgorica (GMT+2:00)',
  'Europe/Prague' = 'Europe/Prague (GMT+2:00)',
  'Europe/Rome' = 'Europe/Rome (GMT+2:00)',
  'Europe/San_Marino' = 'Europe/San Marino (GMT+2:00)',
  'Europe/Sarajevo' = 'Europe/Sarajevo (GMT+2:00)',
  'Europe/Skopje' = 'Europe/Skopje (GMT+2:00)',
  'Europe/Stockholm' = 'Europe/Stockholm (GMT+2:00)',
  'Europe/Tirane' = 'Europe/Tirane (GMT+2:00)',
  'Europe/Vaduz' = 'Europe/Vaduz (GMT+2:00)',
  'Europe/Vatican' = 'Europe/Vatican (GMT+2:00)',
  'Europe/Vienna' = 'Europe/Vienna (GMT+2:00)',
  'Europe/Warsaw' = 'Europe/Warsaw (GMT+2:00)',
  'Europe/Zagreb' = 'Europe/Zagreb (GMT+2:00)',
  'Europe/Zurich' = 'Europe/Zurich (GMT+2:00)',
  'Africa/Addis_Ababa' = 'Africa/Addis Ababa (GMT+3:00)',
  'Africa/Asmara' = 'Africa/Asmara (GMT+3:00)',
  'Africa/Dar_es_Salaam' = 'Africa/Dar es Salaam (GMT+3:00)',
  'Africa/Djibouti' = 'Africa/Djibouti (GMT+3:00)',
  'Africa/Kampala' = 'Africa/Kampala (GMT+3:00)',
  'Africa/Mogadishu' = 'Africa/Mogadishu (GMT+3:00)',
  'Africa/Nairobi' = 'Africa/Nairobi (GMT+3:00)',
  'Antarctica/Syowa' = 'Antarctica/Syowa (GMT+3:00)',
  'Asia/Aden' = 'Asia/Aden (GMT+3:00)',
  'Asia/Amman' = 'Asia/Amman (GMT+3:00)',
  'Asia/Baghdad' = 'Asia/Baghdad (GMT+3:00)',
  'Asia/Bahrain' = 'Asia/Bahrain (GMT+3:00)',
  'Asia/Beirut' = 'Asia/Beirut (GMT+3:00)',
  'Asia/Damascus' = 'Asia/Damascus (GMT+3:00)',
  'Asia/Famagusta' = 'Asia/Famagusta (GMT+3:00)',
  'Asia/Gaza' = 'Asia/Gaza (GMT+3:00)',
  'Asia/Hebron' = 'Asia/Hebron (GMT+3:00)',
  'Asia/Jerusalem' = 'Asia/Jerusalem (GMT+3:00)',
  'Asia/Kuwait' = 'Asia/Kuwait (GMT+3:00)',
  'Asia/Nicosia' = 'Asia/Nicosia (GMT+3:00)',
  'Asia/Qatar' = 'Asia/Qatar (GMT+3:00)',
  'Asia/Riyadh' = 'Asia/Riyadh (GMT+3:00)',
  'Europe/Athens' = 'Europe/Athens (GMT+3:00)',
  'Europe/Bucharest' = 'Europe/Bucharest (GMT+3:00)',
  'Europe/Chisinau' = 'Europe/Chisinau (GMT+3:00)',
  'Europe/Helsinki' = 'Europe/Helsinki (GMT+3:00)',
  'Europe/Istanbul' = 'Europe/Istanbul (GMT+3:00)',
  'Europe/Kirov' = 'Europe/Kirov (GMT+3:00)',
  'Europe/Kyiv' = 'Europe/Kyiv (GMT+3:00)',
  'Europe/Mariehamn' = 'Europe/Mariehamn (GMT+3:00)',
  'Europe/Minsk' = 'Europe/Minsk (GMT+3:00)',
  'Europe/Moscow' = 'Europe/Moscow (GMT+3:00)',
  'Europe/Riga' = 'Europe/Riga (GMT+3:00)',
  'Europe/Simferopol' = 'Europe/Simferopol (GMT+3:00)',
  'Europe/Sofia' = 'Europe/Sofia (GMT+3:00)',
  'Europe/Tallinn' = 'Europe/Tallinn (GMT+3:00)',
  'Europe/Vilnius' = 'Europe/Vilnius (GMT+3:00)',
  'Europe/Volgograd' = 'Europe/Volgograd (GMT+3:00)',
  'Indian/Antananarivo' = 'Indian/Antananarivo (GMT+3:00)',
  'Indian/Comoro' = 'Indian/Comoro (GMT+3:00)',
  'Indian/Mayotte' = 'Indian/Mayotte (GMT+3:00)',
  'Asia/Tehran' = 'Asia/Tehran (GMT+3:30)',
  'Asia/Baku' = 'Asia/Baku (GMT+4:00)',
  'Asia/Dubai' = 'Asia/Dubai (GMT+4:00)',
  'Asia/Muscat' = 'Asia/Muscat (GMT+4:00)',
  'Asia/Tbilisi' = 'Asia/Tbilisi (GMT+4:00)',
  'Asia/Yerevan' = 'Asia/Yerevan (GMT+4:00)',
  'Europe/Astrakhan' = 'Europe/Astrakhan (GMT+4:00)',
  'Europe/Samara' = 'Europe/Samara (GMT+4:00)',
  'Europe/Saratov' = 'Europe/Saratov (GMT+4:00)',
  'Europe/Ulyanovsk' = 'Europe/Ulyanovsk (GMT+4:00)',
  'Indian/Mahe' = 'Indian/Mahe (GMT+4:00)',
  'Indian/Mauritius' = 'Indian/Mauritius (GMT+4:00)',
  'Indian/Reunion' = 'Indian/Reunion (GMT+4:00)',
  'Asia/Kabul' = 'Asia/Kabul (GMT+4:30)',
  'Antarctica/Mawson' = 'Antarctica/Mawson (GMT+5:00)',
  'Asia/Aqtau' = 'Asia/Aqtau (GMT+5:00)',
  'Asia/Aqtobe' = 'Asia/Aqtobe (GMT+5:00)',
  'Asia/Ashgabat' = 'Asia/Ashgabat (GMT+5:00)',
  'Asia/Atyrau' = 'Asia/Atyrau (GMT+5:00)',
  'Asia/Dushanbe' = 'Asia/Dushanbe (GMT+5:00)',
  'Asia/Karachi' = 'Asia/Karachi (GMT+5:00)',
  'Asia/Oral' = 'Asia/Oral (GMT+5:00)',
  'Asia/Qyzylorda' = 'Asia/Qyzylorda (GMT+5:00)',
  'Asia/Samarkand' = 'Asia/Samarkand (GMT+5:00)',
  'Asia/Tashkent' = 'Asia/Tashkent (GMT+5:00)',
  'Asia/Yekaterinburg' = 'Asia/Yekaterinburg (GMT+5:00)',
  'Indian/Kerguelen' = 'Indian/Kerguelen (GMT+5:00)',
  'Indian/Maldives' = 'Indian/Maldives (GMT+5:00)',
  'Asia/Colombo' = 'Asia/Colombo (GMT+5:30)',
  'Asia/Kolkata' = 'Asia/Kolkata (GMT+5:30)',
  'Asia/Kathmandu' = 'Asia/Kathmandu (GMT+5:45)',
  'Antarctica/Vostok' = 'Antarctica/Vostok (GMT+6:00)',
  'Asia/Almaty' = 'Asia/Almaty (GMT+6:00)',
  'Asia/Bishkek' = 'Asia/Bishkek (GMT+6:00)',
  'Asia/Dhaka' = 'Asia/Dhaka (GMT+6:00)',
  'Asia/Omsk' = 'Asia/Omsk (GMT+6:00)',
  'Asia/Qostanay' = 'Asia/Qostanay (GMT+6:00)',
  'Asia/Thimphu' = 'Asia/Thimphu (GMT+6:00)',
  'Asia/Urumqi' = 'Asia/Urumqi (GMT+6:00)',
  'Indian/Chagos' = 'Indian/Chagos (GMT+6:00)',
  'Asia/Yangon' = 'Asia/Yangon (GMT+6:30)',
  'Indian/Cocos' = 'Indian/Cocos (GMT+6:30)',
  'Antarctica/Davis' = 'Antarctica/Davis (GMT+7:00)',
  'Asia/Bangkok' = 'Asia/Bangkok (GMT+7:00)',
  'Asia/Barnaul' = 'Asia/Barnaul (GMT+7:00)',
  'Asia/Ho_Chi_Minh' = 'Asia/Ho Chi Minh (GMT+7:00)',
  'Asia/Hovd' = 'Asia/Hovd (GMT+7:00)',
  'Asia/Jakarta' = 'Asia/Jakarta (GMT+7:00)',
  'Asia/Krasnoyarsk' = 'Asia/Krasnoyarsk (GMT+7:00)',
  'Asia/Novokuznetsk' = 'Asia/Novokuznetsk (GMT+7:00)',
  'Asia/Novosibirsk' = 'Asia/Novosibirsk (GMT+7:00)',
  'Asia/Phnom_Penh' = 'Asia/Phnom Penh (GMT+7:00)',
  'Asia/Pontianak' = 'Asia/Pontianak (GMT+7:00)',
  'Asia/Tomsk' = 'Asia/Tomsk (GMT+7:00)',
  'Asia/Vientiane' = 'Asia/Vientiane (GMT+7:00)',
  'Indian/Christmas' = 'Indian/Christmas (GMT+7:00)',
  'Asia/Brunei' = 'Asia/Brunei (GMT+8:00)',
  'Asia/Choibalsan' = 'Asia/Choibalsan (GMT+8:00)',
  'Asia/Hong_Kong' = 'Asia/Hong Kong (GMT+8:00)',
  'Asia/Irkutsk' = 'Asia/Irkutsk (GMT+8:00)',
  'Asia/Kuala_Lumpur' = 'Asia/Kuala Lumpur (GMT+8:00)',
  'Asia/Kuching' = 'Asia/Kuching (GMT+8:00)',
  'Asia/Macau' = 'Asia/Macau (GMT+8:00)',
  'Asia/Makassar' = 'Asia/Makassar (GMT+8:00)',
  'Asia/Manila' = 'Asia/Manila (GMT+8:00)',
  'Asia/Shanghai' = 'Asia/Shanghai (GMT+8:00)',
  'Asia/Singapore' = 'Asia/Singapore (GMT+8:00)',
  'Asia/Taipei' = 'Asia/Taipei (GMT+8:00)',
  'Asia/Ulaanbaatar' = 'Asia/Ulaanbaatar (GMT+8:00)',
  'Australia/Perth' = 'Australia/Perth (GMT+8:00)',
  'Australia/Eucla' = 'Australia/Eucla (GMT+8:45)',
  'Asia/Chita' = 'Asia/Chita (GMT+9:00)',
  'Asia/Dili' = 'Asia/Dili (GMT+9:00)',
  'Asia/Jayapura' = 'Asia/Jayapura (GMT+9:00)',
  'Asia/Khandyga' = 'Asia/Khandyga (GMT+9:00)',
  'Asia/Pyongyang' = 'Asia/Pyongyang (GMT+9:00)',
  'Asia/Seoul' = 'Asia/Seoul (GMT+9:00)',
  'Asia/Tokyo' = 'Asia/Tokyo (GMT+9:00)',
  'Asia/Yakutsk' = 'Asia/Yakutsk (GMT+9:00)',
  'Pacific/Palau' = 'Pacific/Palau (GMT+9:00)',
  'Australia/Darwin' = 'Australia/Darwin (GMT+9:30)',
  'Antarctica/DumontDUrville' = 'Antarctica/DumontDUrville (GMT+10:00)',
  'Asia/Ust-Nera' = 'Asia/Ust-Nera (GMT+10:00)',
  'Asia/Vladivostok' = 'Asia/Vladivostok (GMT+10:00)',
  'Australia/Brisbane' = 'Australia/Brisbane (GMT+10:00)',
  'Australia/Lindeman' = 'Australia/Lindeman (GMT+10:00)',
  'Pacific/Chuuk' = 'Pacific/Chuuk (GMT+10:00)',
  'Pacific/Guam' = 'Pacific/Guam (GMT+10:00)',
  'Pacific/Port_Moresby' = 'Pacific/Port Moresby (GMT+10:00)',
  'Pacific/Saipan' = 'Pacific/Saipan (GMT+10:00)',
  'Australia/Adelaide' = 'Australia/Adelaide (GMT+10:30)',
  'Australia/Broken_Hill' = 'Australia/Broken Hill (GMT+10:30)',
  'Antarctica/Casey' = 'Antarctica/Casey (GMT+11:00)',
  'Antarctica/Macquarie' = 'Antarctica/Macquarie (GMT+11:00)',
  'Asia/Magadan' = 'Asia/Magadan (GMT+11:00)',
  'Asia/Sakhalin' = 'Asia/Sakhalin (GMT+11:00)',
  'Asia/Srednekolymsk' = 'Asia/Srednekolymsk (GMT+11:00)',
  'Australia/Hobart' = 'Australia/Hobart (GMT+11:00)',
  'Australia/Lord_Howe' = 'Australia/Lord Howe (GMT+11:00)',
  'Australia/Melbourne' = 'Australia/Melbourne (GMT+11:00)',
  'Australia/Sydney' = 'Australia/Sydney (GMT+11:00)',
  'Pacific/Bougainville' = 'Pacific/Bougainville (GMT+11:00)',
  'Pacific/Efate' = 'Pacific/Efate (GMT+11:00)',
  'Pacific/Guadalcanal' = 'Pacific/Guadalcanal (GMT+11:00)',
  'Pacific/Kosrae' = 'Pacific/Kosrae (GMT+11:00)',
  'Pacific/Noumea' = 'Pacific/Noumea (GMT+11:00)',
  'Pacific/Pohnpei' = 'Pacific/Pohnpei (GMT+11:00)',
  'Asia/Anadyr' = 'Asia/Anadyr (GMT+12:00)',
  'Asia/Kamchatka' = 'Asia/Kamchatka (GMT+12:00)',
  'Pacific/Fiji' = 'Pacific/Fiji (GMT+12:00)',
  'Pacific/Funafuti' = 'Pacific/Funafuti (GMT+12:00)',
  'Pacific/Kwajalein' = 'Pacific/Kwajalein (GMT+12:00)',
  'Pacific/Majuro' = 'Pacific/Majuro (GMT+12:00)',
  'Pacific/Nauru' = 'Pacific/Nauru (GMT+12:00)',
  'Pacific/Norfolk' = 'Pacific/Norfolk (GMT+12:00)',
  'Pacific/Tarawa' = 'Pacific/Tarawa (GMT+12:00)',
  'Pacific/Wake' = 'Pacific/Wake (GMT+12:00)',
  'Pacific/Wallis' = 'Pacific/Wallis (GMT+12:00)',
  'Antarctica/McMurdo' = 'Antarctica/McMurdo (GMT+13:00)',
  'Pacific/Apia' = 'Pacific/Apia (GMT+13:00)',
  'Pacific/Auckland' = 'Pacific/Auckland (GMT+13:00)',
  'Pacific/Fakaofo' = 'Pacific/Fakaofo (GMT+13:00)',
  'Pacific/Kanton' = 'Pacific/Kanton (GMT+13:00)',
  'Pacific/Tongatapu' = 'Pacific/Tongatapu (GMT+13:00)',
  'Pacific/Chatham' = 'Pacific/Chatham (GMT+13:45)',
  'Pacific/Kiritimati' = 'Pacific/Kiritimati (GMT+14:00)'
}

export default userTimeZone;
