import CancelButton from 'components/form/button-cancel';
import SaveButton from 'components/form/button-save';
import PaperBox from 'components/paper-box';
import PaperBoxContent from 'components/paper-box/paper-box-content';
import StackRowWithDivider from 'components/stack/stack-row-with-divider';
import { getSubTypeOptions } from 'helpers/document/document-helper';
import { validateDocument } from 'helpers/validation/document-helper';
import useRouteName from 'pages/route-outlet-context';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import documentService from 'services/document-service';
import initialDocument from 'state/document/initital-document';
import { DocumentEntity, DocumentPayloadEntity } from 'types/document-types';
import { OptionType } from 'types/option-type';
import RecordView from '../record-view';
import { ErrorValidation } from 'types/error-types';
import ErrorComponent from 'components/errors/error-component';
import { isEmpty } from 'helpers/misc-helper';
import { InputChangeEvent } from 'types/common-types';

const DocumentCreate = ({ routeTag }: { routeTag: string }) => {
  const { document_id, market_preference_id } = useParams<{
    document_id: string;

    market_preference_id: string;
  }>();

  const { setRouteName } = useRouteName();

  const fileInput = useRef<HTMLInputElement>(null);
  const documentName = useRef<string>('');
  const [subTypeOptions, setSubtypeOption] = useState<OptionType[]>([]);
  const [document, setDocument] = useState<DocumentEntity>(initialDocument);
  const [validation, setValidation] = useState<ErrorValidation>({});
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [selectedFile, setSelectedFile] = useState<string | Blob | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const browseFiles = (): void => {
    const files: FileList = fileInput.current! && fileInput.current.files!;

    setDocument((preDoc) => ({ ...preDoc, document_name: files[0].name }));
    setSelectedFile(files[0]);
    documentName.current = files[0].name;
  };

  const handleChange = (e: InputChangeEvent): void => {
    setDocument(
      Object.assign({}, document, { [e.target.name]: e.target.value })
    );
  };

  const saveDocument = async (): Promise<void> => {
    const { status, errors } = validateDocument(
      document,
      !document_id,
      selectedFile
    );

    setValidation(errors);

    if (!status) return;

    if (document_id) {
      const reqBody: DocumentPayloadEntity = {
        document_status: document.status_id,
        document_state: document.document_state,
        document_subtype: document.document_subtype,
        document_type: document.category_id!,
        revision: document.document_revision.revision,
        document_revision: document.document_revision,
        document_name: document.document_name
      };

      setIsLoading(true);
      const result = await documentService.update(document_id, reqBody);
      setIsLoading(false);

      if (result.isValidationError) {
        setValidation(result.validationMessage);
        return;
      }

      if (result.isError) {
        setErrorMessage(result.errorMessage.message);
        return;
      }

      if (result.isSuccess) {
        window.history.back();
      }
    } else {
      const reqBody = new FormData();

      reqBody.append(`documents[0][file]`, selectedFile!);
      reqBody.append(`documents[0][document_type]`, document.category_id!);
      reqBody.append(
        `documents[0][document_subtype]`,
        document.document_subtype!
      );
      reqBody.append(`documents[0][document_status]`, document.status_id!);
      reqBody.append(`documents[0][document_state]`, document.document_state!);
      reqBody.append(
        `documents[0][revision]`,
        document.document_revision.revision!
      );
      if (market_preference_id) {
        reqBody.append(`market_preference_id`, market_preference_id);
      }

      setIsLoading(true);
      const result = await documentService.create(reqBody);
      setIsLoading(false);
      console.log(result);
      if (result.isValidationError) {
        setValidation(result.validationMessage);
        return;
      }

      if (result.isSuccess) {
        window.history.back();
      }
    }
  };

  const loadDocument = async (document_id: string): Promise<void> => {
    const document = await documentService.get(document_id);
    setDocument(document.data);
    documentName.current = document.data.document_name;
  };

  useEffect(() => {
    setSubtypeOption(
      getSubTypeOptions(document.category_id, document.document_subtype)
    );
  }, [document.category_id]);

  useEffect(() => {
    if (document_id) loadDocument(document_id);
  }, []);

  useEffect(() => {
    setRouteName(routeTag !== undefined ? routeTag : '');
  }, []);

  return (
    <PaperBox>
      <PaperBoxContent>
        {isEmpty(errorMessage) ? (
          <>
            <RecordView
              document={document}
              setDocument={setDocument}
              subTypeOptions={subTypeOptions}
              validation={validation}
              onChange={handleChange}
              fileInput={fileInput}
              browseFiles={browseFiles}
              document_id={document_id}
            />
            <StackRowWithDivider>
              <SaveButton onClick={() => saveDocument()} disabled={isLoading} />
              <CancelButton />
            </StackRowWithDivider>
          </>
        ) : (
          <ErrorComponent message={errorMessage} />
        )}
      </PaperBoxContent>
    </PaperBox>
  );
};

export default DocumentCreate;
