const initialComposeMail = {
  from: '',
  reply_to: '',
  to: '',
  cc: '',
  bcc: '',
  subject: '',
  template: '',
  template_type: '',
  opportunity_id: '',
  opportunity_name: '',
  email_id: '',
  body_html: '',
  from_name: '',
  notes: [],
  count: 0,
  status: ''
};

export default initialComposeMail;
