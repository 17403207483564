import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import {
  DocumentFilterEntity,
  DocumentsListEntity
} from 'types/document-types';
import { GridPaginationModel, GridSortModel } from '@mui/x-data-grid';
import { isEmpty } from 'helpers/misc-helper';
import { ObjectType } from 'types';
import DataGrid from 'components/data-grid/hoc-datagrid';
import defaultSort from 'assets/list/document/default-sort';
import documentColumns from 'assets/list/document/column';
import DocumentService from 'services/document-service';
import initialListFilter from 'state/document/initial-document-list-filter';
import listQueryString, { prepareSort } from 'helpers/query-string-helper';
import useRouteName from 'pages/route-outlet-context';
import Filters from './filter';
import { OpportunityContext } from 'pages/opportunity/Context';
import { InputChangeEvent } from 'types/common-types';
import { GetListResponse } from 'types/api-response-types';

const DocumentList = ({
  routeTag,
  id,
  isFilterDisplay = true
}: {
  routeTag?: string;
  id?: string;
  isFilterDisplay?: boolean;
}) => {
  const { market_preference_id, opportunity_id } = useParams<{
    market_preference_id: string;
    opportunity_id: string;
  }>();
  const location = useLocation();

  const outletContext = useRouteName();

  const [filter, setFilter] = useState<DocumentFilterEntity>(initialListFilter);

  const { oppurtunity } = useContext(OpportunityContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [initialLoad, setInitialLoad] = useState<boolean>(false);
  const [isFilterChanged, setIsFilterChanged] = useState<boolean>(false);

  const updateFilter = (e: InputChangeEvent): void => {
    setFilter(Object.assign({}, filter, { [e.target.name]: e.target.value }));
  };

  const getDocuments = async (
    paginationModel: GridPaginationModel,
    sortModel: GridSortModel
  ): Promise<GetListResponse<DocumentsListEntity>> => {
    let filterInfo;
    if (market_preference_id !== undefined) {
      filterInfo = { ...filter, market_preference_id };
    } else if (opportunity_id !== undefined) {
      if (location.pathname.endsWith('generate_contract')) {
        filterInfo = {
          ...filter,
          market_preference_id: oppurtunity?.market_preference_id,
          document_status: 'active'
        };
      } else {
        filterInfo = {
          ...filter,
          opportunity_id
        };
      }
    } else {
      filterInfo = filter;
    }

    const queryString = listQueryString({
      pagination: paginationModel,
      sort: prepareSort(sortModel, defaultSort),
      filter: filterInfo
    });

    setIsLoading(true);

    const result = await DocumentService.getList(queryString);

    setIsLoading(false);
    return result;
  };

  useEffect(() => {
    if (!isEmpty(outletContext) && !isEmpty(routeTag))
      outletContext.setRouteName(routeTag !== undefined ? routeTag : '');
  }, []);

  return (
    <React.Fragment>
      {isFilterDisplay && (
        <Filters
          filter={filter}
          updateFilter={updateFilter}
          setIsFilterChanged={setIsFilterChanged}
          setFilter={setFilter}
          isLoading={isLoading}
        />
      )}

      <DataGrid<DocumentsListEntity>
        getData={getDocuments}
        tableColumns={documentColumns}
        isFilterChanged={isFilterChanged}
        setIsFilterChanged={setIsFilterChanged}
        initialLoad={initialLoad}
        setInitialLoad={setInitialLoad}
        isLoading={isLoading}
        refreshEvent="document"
      />
    </React.Fragment>
  );
};

export default DocumentList;
