import { AccountEntity } from 'types/account-types';
import { ErrorValidation } from 'types/error-types';
import { InputChangeEvent } from 'types/common-types';
import accountCustomerType from 'assets/constants/account-customer-type';
import accountStatusType from 'assets/constants/account-status-type';
import emptyFunction from 'helpers/empty-function-helper';
import fieldLabel from 'assets/constants/fieldLabel';
import FormContainer from 'components/form/container';
import getObjectEntriesAsArray from 'helpers/object-field-helper';
import UnitSelect from 'components/form/unit-select';
import UnitText from 'components/form/unit-text';

interface recordViewType {
  account: AccountEntity;
  validation?: ErrorValidation;
  onChange?: (e: InputChangeEvent) => void;
  readOnly?: boolean;
}

const RecordView = ({
  account,
  validation,
  onChange,
  readOnly = false
}: recordViewType) => {
  const handleChange: (e: InputChangeEvent) => void = onChange ?? emptyFunction;
  const valMessages: ErrorValidation = validation ?? {};

  return (
    <FormContainer>
      <UnitText
        label={fieldLabel.accountName}
        name="name"
        value={account.name ?? ''}
        onChange={handleChange}
        error={valMessages['name'] ?? ''}
        required
        readOnly={readOnly}
      />

      <UnitText
        label={fieldLabel.enteraCustomerId}
        name="entera_customer_id"
        value={account.entera_customer_id ?? ''}
        error={valMessages['entera_customer_id'] ?? ''}
        onChange={handleChange}
        required
        readOnly={readOnly}
      />

      <UnitText
        label={fieldLabel.companyName}
        name="company_name"
        value={account.company_name ?? ''}
        onChange={handleChange}
        error={valMessages['company_name'] ?? ''}
        required
        readOnly={readOnly}
      />

      <UnitText
        label={fieldLabel.contactFirstName}
        name="contact_first_name"
        value={account.contact_first_name ?? ''}
        onChange={handleChange}
        error={valMessages['contact_first_name'] ?? ''}
        required
        readOnly={readOnly}
      />

      <UnitText
        label={fieldLabel.contactLastName}
        name="contact_last_name"
        value={account.contact_last_name ?? ''}
        onChange={handleChange}
        error={valMessages['contact_last_name'] ?? ''}
        required
        readOnly={readOnly}
      />

      <UnitText
        label={fieldLabel.website}
        name="website"
        value={account.website ?? ''}
        onChange={handleChange}
        readOnly={readOnly}
      />

      <UnitText
        label={fieldLabel.companyPhone}
        name="phone_office"
        value={account.phone_office ?? ''}
        error={valMessages['phone_office'] ?? ''}
        onChange={handleChange}
        readOnly={readOnly}
        required
      />

      <UnitText
        label={fieldLabel.fax}
        name="phone_fax"
        value={account.phone_fax ?? ''}
        error={valMessages['phone_fax'] ?? ''}
        onChange={handleChange}
        readOnly={readOnly}
      />

      <UnitSelect
        name="account_type"
        label={fieldLabel.customerType}
        records={getObjectEntriesAsArray(accountCustomerType)}
        value={account.account_type ?? ''}
        onChange={handleChange}
        readOnly={readOnly}
      />

      <UnitSelect
        name="account_status"
        label={fieldLabel.accountStatus}
        records={getObjectEntriesAsArray(accountStatusType)}
        value={account.account_status ?? ''}
        onChange={handleChange}
        readOnly={readOnly}
      />
    </FormContainer>
  );
};

export default RecordView;
