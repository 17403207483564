
const initialProfile = {
    address_city: '',
    address_country: '',
    address_postalcode: '',
    address_state: '',
    address_street: '',
    crm_user_type: '',
    description: '',
    email: '',
    employee_status: '',
    first_name: '',
    id: '',
    is_admin: 0,
    last_name: '',
    phone_mobile: '',
    phone_work: '',
    photo: '',
    status: '',
    timezone: '',
    title: '',
    user_name: '',
}

export default initialProfile