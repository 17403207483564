import { AccountEntity } from 'types/account-types';
import { ErrorValidation } from 'types/error-types';
import { InputChangeEvent } from 'types/common-types';
import { moduleLabels } from 'assets/list/tracker/constant';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { validateAccount } from 'helpers/validation/account-helper';
import accountService from 'services/account-service';
import CancelButton from 'components/form/button-cancel';
import initialAccount from 'state/account/initial-account';
import PaperBox from 'components/paper-box';
import PaperBoxContent from 'components/paper-box/paper-box-content';
import RecordView from '../record-view';
import SaveButton from 'components/form/button-save';
import StackRowWithDivider from 'components/stack/stack-row-with-divider';
import trackerService from 'services/tracker-service';
import useRouteName from 'pages/route-outlet-context';
import ErrorComponent from 'components/errors/error-component';
import { isEmpty } from 'helpers/misc-helper';

const AccountCreate = ({ routeTag }: { routeTag: string }) => {
  const { setRouteName } = useRouteName();
  const { account_id } = useParams();
  const [account, setAccount] = useState<AccountEntity>(initialAccount);
  const [validation, setValidation] = useState<ErrorValidation>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const handleChange = (e: InputChangeEvent) => {
    setAccount(Object.assign({}, account, { [e.target.name]: e.target.value }));
  };

  const loadAccount = async (account_id: string) => {
    const response = await accountService.get(account_id);

    if (response.isError) {
      setErrorMessage(response.errorMessage.message);
      return;
    }

    setAccount(response.data);

    trackerService.createRecentlyViewed({
      module_name: moduleLabels.Accounts.label,
      item_id: account_id,
      item_summary: response.data.name,
      action: 'detailview'
    });
  };

  const handleSubmit = async (): Promise<void> => {
    const { status, ...errors } = validateAccount(account);
    let response;

    setValidation(errors);

    setIsLoading(true);

    if (!status) {
      setIsLoading(false);
      return;
    }

    const requestBody: AccountEntity = {
      name: account.name,
      entera_customer_id: account.entera_customer_id,
      company_name: account.company_name,
      contact_first_name: account.contact_first_name,
      contact_last_name: account.contact_last_name,
      website: account.website,
      phone_office: account.phone_office,
      phone_fax: account.phone_fax,
      account_type: account.account_type,
      account_status: account.account_status
    };

    if (account_id) {
      response = await accountService.update(account_id, requestBody);
    } else {
      response = await accountService.create(requestBody);
    }

    setIsLoading(false);

    if (response.isValidationError) {
      setValidation(response.validationMessage);
    }

    if (response.isSuccess) {
      window.history.back();
    }
  };

  useEffect(() => {
    setRouteName(routeTag);
  });

  useEffect(() => {
    if (account_id !== undefined) loadAccount(account_id);
  }, []);

  return (
    <PaperBox>
      <PaperBoxContent>
        {isEmpty(errorMessage) ? (
          <>
            <RecordView
              account={account}
              validation={validation}
              onChange={handleChange}
            />

            <StackRowWithDivider>
              <SaveButton onClick={handleSubmit} disabled={isLoading} />
              <CancelButton />
            </StackRowWithDivider>
          </>
        ) : (
          <ErrorComponent message={errorMessage} />
        )}
      </PaperBoxContent>
    </PaperBox>
  );
};

export default AccountCreate;
