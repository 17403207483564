import fieldLabel from 'assets/constants/fieldLabel';
import { missingField } from 'assets/validation-template';
import { isEmpty } from 'helpers/misc-helper';
import { DocumentEntity, DocumentUploadItem } from 'types/document-types';
import { ErrorValidation } from 'types/error-types';

const isValidFileExtention = (filename: string) => {
  const validFileTypes = ['jpeg', 'jpg', 'png', 'zip', 'pdf', 'docx', 'doc'];
  const fileExtenstion = filename.split('.').pop()?.toLowerCase();
  const isValid = validFileTypes.includes(fileExtenstion!);
  return isValid;
};

export const isAmendmentsOrAddendumsDocType = (category_id: string) => {
  return ['amendments', 'addendums'].includes(category_id);
};

export const validateDocument = (
  data: DocumentEntity,
  isCreate = false,
  file?: string | Blob | null
): {
  status: boolean;
  errors: ErrorValidation;
} => {
  let validation = {
    status: true,
    errors: {}
  };

  if (isEmpty(data.document_name)) {
    validation = {
      ...validation,
      status: false,
      errors: {
        ...validation.errors,
        'documents.0.document_name': [missingField(fieldLabel.documentName)]
      }
    };
  }

  if (isCreate) {
    if (isEmpty(file)) {
      validation = {
        ...validation,
        status: false,
        errors: {
          ...validation.errors,
          'documents.0.document_name': ['Please select document']
        }
      };
    }
  }

  if (isEmpty(data.document_revision.revision)) {
    validation = {
      ...validation,
      status: false,
      errors: {
        ...validation.errors,
        'documents.0.revision': [missingField(fieldLabel.revision)]
      }
    };
  }

  if (isEmpty(data.category_id)) {
    validation = {
      ...validation,
      status: false,
      errors: {
        ...validation.errors,
        'documents.0.document_type': [missingField(fieldLabel.documentType)]
      }
    };
  }

  if (data.document_name) {
    if (!isValidFileExtention(data.document_name)) {
      validation = {
        ...validation,
        status: false,
        errors: {
          ...validation.errors,
          document_name: [
            `${fieldLabel.invalidFileType}. ${fieldLabel.allowedFileTypes} are ('.jpeg', '.jpg', '.png', '.zip', '.pdf', '.docx', '.doc')`
          ]
        }
      };
    }
  }

  return validation;
};

export const validateDocumentRivision = (
  data: DocumentUploadItem,
  index: number,
  opportunityIsCreatedAfterExtThreshholdDate?: boolean
): {
  status: boolean;
  errors: ErrorValidation;
} => {
  let validation = {
    status: true,
    errors: {}
  };

  const typeList = [
    'proof_of_funds',
    'purchase_contract',
    'hoa_documents',
    'inspection_reports',
    'disclosures',
    'commission_instructions',
    'addendums',
    'amendments',
    'notice_to_terminate',
    'closing',
    'invoice',
    'sales',
    'misc'
  ];

  if (isEmpty(data.category_id)) {
    validation = {
      ...validation,
      status: false,
      errors: {
        ...validation.errors,
        document_type: [missingField('Document Type')],
        [`documents.${index}.document_type`]: [
          missingField(fieldLabel.documentType)
        ]
      }
    };
  }

  if (
    !isEmpty(data.category_id) &&
    typeList.includes(data.category_id) &&
    isEmpty(data.sub_type)
  ) {
    validation = {
      ...validation,
      status: false,
      errors: {
        ...validation.errors,
        [`documents.${index}.document_subtype`]: [
          missingField(fieldLabel.documentSubType)
        ]
      }
    };
  }

  if (
    opportunityIsCreatedAfterExtThreshholdDate &&
    !isEmpty(data.category_id) &&
    isAmendmentsOrAddendumsDocType(data.category_id) &&
    !isEmpty(data.sub_type) &&
    isEmpty(data.document_extension)
  ) {
    validation = {
      ...validation,
      status: false,
      errors: {
        ...validation.errors,
        [`documents.${index}.document_extension`]: [
          missingField(fieldLabel.extensionNumber)
        ]
      }
    };
  }

  if (isEmpty(data.state)) {
    validation = {
      ...validation,
      status: false,
      errors: {
        ...validation.errors,
        [`documents.${index}.document_state`]: [
          missingField(fieldLabel.documentState)
        ]
      }
    };
  }

  if (isEmpty(data.status)) {
    validation = {
      ...validation,
      status: false,
      errors: {
        ...validation.errors,
        [`documents.${index}.document_status`]: [
          missingField(fieldLabel.documentStatus)
        ]
      }
    };
  }

  if (data.file.name) {
    if (!isValidFileExtention(data.file.name)) {
      validation = {
        ...validation,
        status: false,
        errors: {
          ...validation.errors,
          [`documents.${index}.document_name`]: [
            `${fieldLabel.invalidFileType}. ${fieldLabel.allowedFileTypes} are ('.jpeg', '.jpg', '.png', '.zip', '.pdf', '.docx', '.doc')`
          ]
        }
      };
    }
  }

  return validation;
};
