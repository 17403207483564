import ErrorComponent from 'components/errors/error-component';
import CancelButton from 'components/form/button-cancel';
import SaveButton from 'components/form/button-save';
import PaperBox from 'components/paper-box';
import PaperBoxContent from 'components/paper-box/paper-box-content';
import StackRowWithDivider from 'components/stack/stack-row-with-divider';
import { getSubTypeOptions } from 'helpers/document/document-helper';
import { isEmpty } from 'helpers/misc-helper';
import { validatePdfTemplate } from 'helpers/validation/pdf-template-helper';
import useRouteName from 'pages/route-outlet-context';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import marketPreferenceService from 'services/market-preference-service';
import pdfTemplateService from 'services/pdf-template-service';
import initialPdfTemplate from 'state/contracts/initial-pdf-template';
import { InputChangeEvent } from 'types/common-types';
import { ErrorValidation } from 'types/error-types';
import { AssociatePDFTemplateMarketPreferenceEntity } from 'types/market-preference-types';
import { OptionType } from 'types/option-type';
import {
  PdfTemplateEntity,
  PdfTemplatePayloadEntity
} from 'types/pdf-template-types';

import RecordView from '../record-view';

export interface ContentType {
  html: string;
  text: string;
}

const PdfTemplateCreate = ({ routeTag }: { routeTag: string }): JSX.Element => {
  const { pdf_template_id, market_preference_id } = useParams<{
    pdf_template_id: string;
    market_preference_id: string;
  }>();
  const { setRouteName } = useRouteName();
  const navigate = useNavigate();

  const [pdfTemplate, setPdfTemplate] =
    useState<PdfTemplateEntity>(initialPdfTemplate);

  const [subtypeOption, setSubtypeOption] = useState<OptionType[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [validation, setValidation] = useState<ErrorValidation>({});

  const [contentBody, setContentBody] = useState<ContentType>({
    html: '',
    text: ''
  });

  const [contentHeader, setContentHeader] = useState<ContentType>({
    html: '',
    text: ''
  });

  const [contentFooter, setContentFooter] = useState<ContentType>({
    html: '',
    text: ''
  });
  const [errorMessage, setErrorMessage] = useState<string>('');

  const handleChange = (e: InputChangeEvent): void => {
    setPdfTemplate(
      Object.assign({}, pdfTemplate, { [e.target.name]: e.target.value })
    );
  };

  const loadPdfTemplate = async (pdf_template_id: string): Promise<void> => {
    let response;
    if (market_preference_id) {
      response = await marketPreferenceService.getAssociatedPDFTemplate(
        market_preference_id!,
        pdf_template_id
      );
    } else {
      response = await pdfTemplateService.get(pdf_template_id);
    }

    if (response.isError) {
      setErrorMessage(response.errorMessage.message);
      return;
    }

    setPdfTemplate(response.data);
    setContentBody({ ...contentBody, html: response.data.description });
    setContentFooter({ ...contentFooter, html: response.data.pdffooter });
    setContentHeader({ ...contentHeader, html: response.data.pdfheader });
  };

  const handleSubmit = async (): Promise<void> => {
    const reqbody: PdfTemplatePayloadEntity = {
      name: pdfTemplate.name || '',
      template_order_c: pdfTemplate.template_order_c || '',
      state_c: pdfTemplate.state_c || '',
      document_state: pdfTemplate.document_state || '',
      category_id: pdfTemplate.category_id || '',
      document_subtype: pdfTemplate.document_subtype || '',
      type: pdfTemplate.type || '',
      expire_version: pdfTemplate.expire_version || 0,
      active: pdfTemplate.active || 0,
      margin_bottom: pdfTemplate.margin_bottom || '',
      margin_footer: pdfTemplate.margin_footer || '',
      margin_left: pdfTemplate.margin_left || '',
      margin_right: pdfTemplate.margin_right || '',
      margin_top: pdfTemplate.margin_top || '',
      margin_header: pdfTemplate.margin_header || '',
      description: contentBody.html,
      pdffooter: contentFooter.html,
      pdfheader: contentHeader.html
    };

    const { hasValidationError, errors } = validatePdfTemplate(
      pdfTemplate,
      market_preference_id!
    );

    setValidation(errors);

    if (hasValidationError) return;

    let response;

    setValidation({});

    setIsLoading(true);

    if (pdf_template_id) {
      if (market_preference_id) {
        const associatePDFReqBody: AssociatePDFTemplateMarketPreferenceEntity =
          {
            homeowner_association: pdfTemplate.homeowner_association,
            offer_package: pdfTemplate.offer_package,
            contract_type: pdfTemplate.contract_type
          };
        response = await marketPreferenceService.updateAssociatePDFTemplate(
          market_preference_id,
          pdf_template_id,
          associatePDFReqBody
        );
        navigate(`/market-preferences/${market_preference_id}/contracts`);
      } else {
        response = await pdfTemplateService.update(pdf_template_id, reqbody);
      }
    } else {
      response = await pdfTemplateService.create(reqbody);
    }

    setIsLoading(false);

    if (response.isValidationError) {
      setValidation(response.validationMessage);
    }

    if (response?.isSuccess) {
      if (market_preference_id) {
        navigate(`/market-preferences/${market_preference_id}/contracts`);
      } else {
        if (pdf_template_id) {
          navigate(`/pdf-templates/${pdf_template_id}/view`);
        } else navigate(`/pdf-templates/${response.data.id}/view`);
      }
    }
  };

  useEffect(() => {
    setSubtypeOption(
      getSubTypeOptions(pdfTemplate.category_id, pdfTemplate.document_subtype)
    );
  }, [pdfTemplate.category_id]);

  useEffect(() => {
    if (pdf_template_id !== undefined) loadPdfTemplate(pdf_template_id);
  }, []);

  useEffect(() => {
    setRouteName(routeTag);
  }, []);

  return (
    <PaperBox>
      <PaperBoxContent>
        {isEmpty(errorMessage) ? (
          <>
            <RecordView
              pdfTemplate={pdfTemplate}
              validation={validation}
              onChange={handleChange}
              contentBody={contentBody.html}
              contentHeader={contentHeader.html}
              contentFooter={contentFooter.html}
              setContentBody={setContentBody}
              setContentHeader={setContentHeader}
              setContentFooter={setContentFooter}
              subTypeOption={subtypeOption}
              setPdfTemplate={setPdfTemplate}
            />

            <StackRowWithDivider>
              <SaveButton onClick={handleSubmit} disabled={isLoading} />
              <CancelButton />
            </StackRowWithDivider>
          </>
        ) : (
          <>
            <ErrorComponent message={errorMessage} />
          </>
        )}
      </PaperBoxContent>
    </PaperBox>
  );
};
export default PdfTemplateCreate;
