import { Box, IconButton, List, ListItem } from '@mui/material';
import { ObjectType } from 'types';
import { v4 as uuid } from 'uuid';
import DeleteIcon from '@mui/icons-material/Delete';
import emptyFunction from 'helpers/empty-function-helper';
import FormContainer from 'components/form/container';
import ModalTitle from 'components/modal/modal-title';
import React, { ReactNode } from 'react';
import StackRowWithDivider from 'components/stack/stack-row-with-divider';
import UnitItem from 'components/form/unit-item';
import fieldLabel from 'assets/constants/fieldLabel';

interface FilePanelProps {
  readOnly: boolean;
  savedFiles?: ObjectType[];
  unSavedFiles?: File[];
  notesFiles: ObjectType[];
  removeFile?: (flag: number, index: number) => void;
  title?: string;
}

const FilePanel = ({
  readOnly = false,
  savedFiles = [],
  unSavedFiles = [],
  notesFiles,
  removeFile = emptyFunction,
  title = fieldLabel.crmDocuments
}: FilePanelProps) => {
  const FileListContainer = (props: {
    listId: string;
    title: string;
    children: ReactNode;
  }) => {
    return (
      <>
        <ModalTitle value={props.title} />
        <Box
          sx={{
            minHeight: '0px',
            maxHeight: '50vh',
            overflowY: 'auto',
            border: '1px solid  #ccc'
          }}
        >
          <List id={props.listId}>{props.children}</List>
        </Box>
      </>
    );
  };

  const FileListItem = (props: {
    index: number;
    name: string;
    flag: number;
  }) => {
    const { index, name, flag } = props;

    return (
      <ListItem
        className="mb-1"
        sx={{
          wordWrap: 'break-word'
        }}
        secondaryAction={
          !readOnly && (
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={() => removeFile(flag, index)}
              color="inherit"
            >
              <DeleteIcon />
            </IconButton>
          )
        }
      >
        <UnitItem grid={{ xs: 12, sm: 12 }} p={0}>
          {`${name}`}
          <StackRowWithDivider />
        </UnitItem>
      </ListItem>
    );
  };

  return (
    <>
      <FormContainer>
        <UnitItem grid={{ xs: 12, sm: 12 }} p={0}>
          {(savedFiles.length > 0 || notesFiles.length > 0) && (
            <>
              <FileListContainer title={title} listId="crm_documents">
                {notesFiles.map((file: ObjectType, index: number) => (
                  <FileListItem
                    key={uuid()}
                    name={file.name}
                    flag={2}
                    index={index}
                  />
                ))}
                {savedFiles.map((file: ObjectType, index: number) => (
                  <FileListItem
                    key={uuid()}
                    name={file.name}
                    flag={1}
                    index={index}
                  />
                ))}
              </FileListContainer>
            </>
          )}
        </UnitItem>
        <UnitItem
          grid={{ xs: 12, sm: 12 }}
          p={0}
          sx={{
            paddingTop:
              savedFiles.length === 0 ? '0px !important' : '16px !important'
          }}
        >
          {unSavedFiles.length > 0 && (
            <>
              <FileListContainer
                title={fieldLabel.localDocuments}
                listId="local_documents"
              >
                {unSavedFiles.map((file: File, index: number) => (
                  <FileListItem
                    key={uuid()}
                    name={file.name}
                    flag={0}
                    index={index}
                  />
                ))}
              </FileListContainer>
            </>
          )}
        </UnitItem>
      </FormContainer>
    </>
  );
};

export default FilePanel;
