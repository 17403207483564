import oppurtunityStatusList from 'assets/constants/oppurtunity-status-list';

export const initialCountOfferReceipt = {
  opportunity_status_c: oppurtunityStatusList.offer_seller_countered,
  counter_offer_price_c: '',
  counter_offer: '',
  selected_categories: [],
  option_period_days_c: '',
  option_days_type_c: '',
  option_amount_c: '',
  exclusion: '',
  inclusion: '',
  earnest_amount_c: '',
  close_date_c: '',
  lease_end_date: '',
  deposit_amount: 0,
  rental_amount: '',
  lease_type: '',
  additional_terms_comments_c: '',
  solar_panel_leased_or_owned: '',
  solar_panel_payoff_amount: '',
  solar_panel_lease_date_expiration: '',
  other_counter_offer_terms: '',
  due_diligence_end_c: ''
};
