import { Box, useTheme } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import documentService from 'services/document-service';
import ValidationError from 'components/errors/validation-error';
import { useNavigate, useParams } from 'react-router-dom';
import DocumentItem from './document-item';
import {
  getTableHeadCell,
  Table,
  TableBody,
  TableHead,
  TableHeadRow
} from 'components/table/table-component';
import { tokens } from 'theme';
import EVENTS from 'assets/constants/events';
import FormContainer from 'components/form/container';
import UnitHeading from 'components/form/unit-heading';
import ButtonAttachFile from 'components/form/button-attach-file';
import UnitItem from 'components/form/unit-item';
import UploadButton from 'components/form/button-upload';
import eventBus from 'helpers/event-bus-helper';
import {
  isAmendmentsOrAddendumsDocType,
  validateDocumentRivision
} from 'helpers/validation/document-helper';
import useRouteName from 'pages/route-outlet-context';
import { isEmpty } from 'helpers/misc-helper';
import { OpportunityContext } from 'pages/opportunity/Context';
import { createDocumentName } from 'helpers/document/document-helper';
import DateUtility from 'helpers/date-helper';
import envConfig from 'config/env';
import { OpportunityEntity } from 'types/opportunity-types';
import emptyFunction from 'helpers/empty-function-helper';
import { ChangeEvent, DispatchSetState } from 'types/common-types';
import { ErrorValidation } from 'types/error-types';
import { DocumentUploadItem } from 'types/document-types';
import { toastError } from 'event/toast-event';

const DocumentUpload = ({
  routeTag,
  action,
  onClose = emptyFunction,
  setActiveTab = emptyFunction,
  opportunity
}: {
  routeTag: string;
  opportunityId: string;
  action: string;
  onClose?: (val: number) => void;
  setActiveTab?: DispatchSetState<number>;
  opportunity?: OpportunityEntity;
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const { opportunity_id } = useParams<{ opportunity_id: string }>();
  const outletContext = useRouteName();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, showError] = useState(false);
  const [documents, setDocuments] = useState<DocumentUploadItem[]>([]);
  const [validation, setValidation] = useState<ErrorValidation>({});

  const { oppurtunity } = useContext(OpportunityContext);
  const fileInput = useRef<HTMLInputElement>(null);

  const maxFileUpload = 10;

  const opportunityIsCreatedAfterExtThreshholdDate =
    DateUtility.isFirstDateGreaterThenSecondDate(
      oppurtunity.date_entered ?? opportunity?.date_entered,
      envConfig.REACT_APP_OPPORTUNITY_IS_CREATED_AFTER_EXT_THRESHHOLD_DATE
    );

  const isFileExist = (name: string) => {
    return documents.filter((x) => x.file.name == name).length > 0
      ? true
      : false;
  };

  function selectFiles(e: ChangeEvent) {
    const files: FileList = fileInput.current! && fileInput.current.files!;

    let count = 1;
    if (files.length > maxFileUpload) {
      eventBus.dispatch(EVENTS.SHOW_TOAST, {
        isError: true,
        message: `Maximum ${maxFileUpload} files upload`
      });
      return;
    }

    for (const file of files) {
      if (isFileExist(file.name)) {
        continue;
      }
      if (documents.length + count > maxFileUpload) {
        break;
      }
      setDocuments((preDocuments) => [
        ...preDocuments,
        {
          file: file,
          category_id: '',
          sub_type: '',
          status: 'published',
          revision: 1,
          state: '',
          document_extension: 0
        }
      ]);
      count++;
    }
    e.target.value = '';
  }

  function removeFile(index: number) {
    setDocuments((preDocuments) =>
      [...preDocuments].filter((x, i) => i !== index)
    );

    setValidation((preValdation) => {
      for (const key in preValdation) {
        if (key.includes(`documents.${index}`)) {
          delete preValdation[key];
        }
      }
      return preValdation;
    });
  }

  const validateDocuments = () => {
    setValidation({});

    let errorCount = 0;
    let index = 0;

    for (const document of documents) {
      const { status, errors } = validateDocumentRivision(
        document,
        index,
        opportunityIsCreatedAfterExtThreshholdDate
      );

      setValidation((preValdation) => ({ ...preValdation, ...errors }));

      if (status == false) {
        errorCount++;
      }
      index++;
    }

    if (errorCount > 0) {
      return true;
    }

    showError(false);
    return false;
  };

  const prepareRequestBody = (): FormData => {
    const requestBody = new FormData();
    requestBody.append('opportunity_id', opportunity_id!);

    for (let index = 0; index < documents.length; index++) {
      const document = documents[index];
      requestBody.append(
        `documents[${index}][document_name]`,
        createDocumentName(document, opportunity || oppurtunity)
      );
      requestBody.append(`documents[${index}][file]`, document.file);

      requestBody.append(
        `documents[${index}][document_type]`,
        document.category_id
      );
      requestBody.append(
        `documents[${index}][document_subtype]`,
        document.sub_type
      );
      if (
        isAmendmentsOrAddendumsDocType(document.category_id) &&
        opportunityIsCreatedAfterExtThreshholdDate
      ) {
        requestBody.append(
          `documents[${index}][document_extension]`,
          document.document_extension.toString()
        );
      }
      requestBody.append(
        `documents[${index}][document_status]`,
        document.status
      );

      requestBody.append(`documents[${index}][document_state]`, document.state);
    }

    return requestBody;
  };

  const uploadDocuments = async () => {
    if (documents.length == 0) {
      showError(true);
      return;
    }

    const hasValidationError = validateDocuments();

    if (hasValidationError) return;

    const requestBody = prepareRequestBody();

    setIsLoading(true);
    const result = await documentService.postDocumentsWithRevision(requestBody);
    setIsLoading(false);

    if (result.isValidationError) {
      setValidation(result.validationMessage);
      return;
    }

    if (result.isError) {
      toastError(result.errorMessage.message);

      return;
    }

    if (result.isSuccess) {
      eventBus.dispatch(EVENTS.SHOW_TOAST, {
        isError: false,
        message: 'Document Uploaded'
      });
      setDocuments([]);
      if (action === 'lease_agreement_document') {
        onClose(1);
      } else if (action === 'edit-view') {
        navigate(`/opportunities/${opportunity_id}/documents`);
      }
    }

    if (action === 'accept-offer') {
      setActiveTab(0);
    }

    setDocuments([]);
  };

  const updateDocuments = (
    index: number,
    newDoc: Partial<DocumentUploadItem>
  ) => {
    setDocuments((preDocs) =>
      [...preDocs].map((doc, i) => (i === index ? { ...doc, ...newDoc } : doc))
    );
  };

  const isSameTypeDocumentSelected = (type: string, sub_type: string) => {
    return [...documents].find(
      (x: DocumentUploadItem) => x.category_id == type && x.sub_type == sub_type
    )
      ? true
      : false;
  };

  const TableHeadCell = getTableHeadCell(colors);

  useEffect(() => {
    if (!isEmpty(useContext) && !isEmpty(routeTag))
      outletContext.setRouteName(routeTag !== undefined ? routeTag : '');
  }, []);

  return (
    <React.Fragment>
      <FormContainer>
        <UnitItem grid={{ xs: 12, sm: 12 }}>
          <Box>
            <UnitHeading
              title={`Selected Files: ${documents.length} (Maximum ${maxFileUpload} files upload)`}
            />
            <ButtonAttachFile
              multiple={true}
              fileInput={fileInput}
              onChange={selectFiles}
              grid={{ xs: 12, sm: 12 }}
              icon={false}
            />

            {error && <ValidationError data={['Please Select File']} />}
            <Box
              sx={{
                maxHeight: 432,
                overflowY: 'scroll',
                margin: '10px'
              }}
            >
              <Table width={'100px'}>
                <TableHead>
                  <TableHeadRow sx={{ backgroundColor: colors.primary[400] }}>
                    <TableHeadCell>Document Name</TableHeadCell>
                    <TableHeadCell>Document Type</TableHeadCell>
                    <TableHeadCell>Document Sub Type</TableHeadCell>
                    <TableHeadCell>Document State</TableHeadCell>
                    <TableHeadCell>Status</TableHeadCell>
                    <TableHeadCell>Version</TableHeadCell>
                  </TableHeadRow>
                </TableHead>
                <TableBody>
                  {documents.map(
                    (document: DocumentUploadItem, index: number) => (
                      <DocumentItem
                        key={index}
                        document={document}
                        index={index}
                        updateDocuments={updateDocuments}
                        removeFile={removeFile}
                        isSameTypeDocumentSelected={isSameTypeDocumentSelected}
                        opportunityId={opportunity_id!}
                        validation={validation}
                        opportunityIsCreatedAfterExtThreshholdDate={
                          opportunityIsCreatedAfterExtThreshholdDate
                        }
                        opportunity={opportunity}
                      />
                    )
                  )}
                </TableBody>
              </Table>
            </Box>
            <UploadButton onClick={uploadDocuments} disabled={isLoading} />
          </Box>
        </UnitItem>
      </FormContainer>
    </React.Fragment>
  );
};

export default DocumentUpload;
