import { missingField } from 'assets/validation-template';
import { isEmpty } from 'helpers/misc-helper';
import { ErrorValidation } from 'types/error-types';
import { ProfileEntity } from 'types/user';

export const validateProfile = (data: ProfileEntity): {
    status: boolean,
    errors: ErrorValidation
} => {
    let validation: {
        status: boolean,
        errors: ErrorValidation
    } = {
        status: true,
        errors: {}
    };

    if (isEmpty(data.user_name)) {
        validation = {
            ...validation,
            status: false,
            errors: {
                ...validation.errors,
                user_name: [missingField('User Name')]
            }
        };
    }

    if (isEmpty(data.first_name)) {
        validation = {
            ...validation,
            status: false,
            errors: {
                ...validation.errors,
                first_name: [missingField('First Name')]
            }
        };
    }

    if (isEmpty(data.email)) {
        validation = {
            ...validation,
            status: false,
            errors: {
                ...validation.errors,
                email: [missingField('email')]
            }
        };
    }

    if (isEmpty(data.last_name)) {
        validation = {
            ...validation,
            status: false,
            errors: {
                ...validation.errors,
                last_name: [missingField('Last Name')]
            }
        };
    }

    if (isEmpty(data.status)) {
        validation = {
            ...validation,
            status: false,
            errors: {
                ...validation.errors,
                user_status: [missingField('Status')]
            }
        };
    }

    if (isEmpty(data.employee_status)) {
        validation = {
            ...validation,
            status: false,
            errors: {
                ...validation.errors,
                employee_status: [missingField('Employee Status')]
            }
        };
    }

    if (isEmpty(data.address_street)) {
        validation = {
            ...validation,
            status: false,
            errors: {
                ...validation.errors,
                address_street: [missingField('Address Street')]
            }
        };
    }

    if (isEmpty(data.address_city)) {
        validation = {
            ...validation,
            status: false,
            errors: {
                ...validation.errors,
                address_city: [missingField('Address City')]
            }
        };
    }

    if (isEmpty(data.address_state)) {
        validation = {
            ...validation,
            status: false,
            errors: {
                ...validation.errors,
                address_state: [missingField('Address State')]
            }
        };
    }

    if (isEmpty(data.address_postalcode)) {
        validation = {
            ...validation,
            status: false,
            errors: {
                ...validation.errors,
                address_postalcode: [missingField('Address postalcode')]
            }
        };
    }

    if (isEmpty(data.address_country)) {
        validation = {
            ...validation,
            status: false,
            errors: {
                ...validation.errors,
                address_country: [missingField('Address Country')]
            }
        };
    }

    if (isEmpty(data.timezone)) {
        validation = {
            ...validation,
            status: false,
            errors: {
                ...validation.errors,
                timezone: [missingField('Time Zone')]
            }
        };
    }
    return validation;
};
