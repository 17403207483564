import FormContainer from 'components/form/container';
import UnitEmpty from 'components/form/unit-empty';
import UnitSelect from 'components/form/unit-select';
import UnitSwitch from 'components/form/unit-switch';
import UnitText from 'components/form/unit-text';
import UnitMsa from 'components/form/unit-msa';
import UnitAccount from 'components/form/unit-account';
import AutoCompleteUnit from 'components/form/auto-complete-unit';
import { ObjectType } from 'types';
import fieldLabel from 'assets/constants/fieldLabel';
import { MarketPreferenceEntity } from 'types/market-preference-types';
import emptyFunction from 'helpers/empty-function-helper';
import crmStatusType from 'assets/constants/crm-status-type';
import opportunityStatus from 'assets/constants/opportunity-status';
import getObjectEntriesAsArray from 'helpers/object-field-helper';
import UnitRead from 'components/form/unit-read';
import RouteLink from 'components/link/route-link';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'helpers/misc-helper';
import { ErrorValidation } from 'types/error-types';
import { ChangeEvent } from 'types/common-types';
import { OptionType } from 'types/option-type';

interface recordViewType {
  marketPreference: MarketPreferenceEntity;
  validation?: ErrorValidation;
  onChange?: (e: ChangeEvent | ObjectType) => void;
  readOnly?: boolean;
}

const RecordView = ({
  marketPreference,
  validation,
  onChange,
  readOnly = false
}: recordViewType) => {
  const handleChange = onChange ?? emptyFunction;
  const valMessages = validation ?? {};

  const { market_preference_id } = useParams();
  return (
    <FormContainer>
      <UnitText
        label={fieldLabel.name}
        name="name"
        value={marketPreference.name ?? ''}
        onChange={handleChange}
        error={valMessages['name'] ?? ''}
        required
        readOnly={readOnly}
      />

      <UnitText
        label={fieldLabel.brokerRepFirm}
        name="broker_rep_firm"
        value={marketPreference.broker_rep_firm ?? ''}
        onChange={handleChange}
        readOnly={readOnly}
      />

      {readOnly === true ? (
        <UnitRead
          label={fieldLabel.msa}
          value={
            <>
              <RouteLink
                url={`${`/msa/${marketPreference.msa_id}/view`}`}
                name={marketPreference.msa_name || '-'}
                target={true}
              />
            </>
          }
        />
      ) : (
        <UnitMsa
          value={{
            value: marketPreference.msa_id,
            label: marketPreference.msa_name ?? ''
          }}
          multiple={false}
          onChange={(val: OptionType) => {
            handleChange({
              msa_id: val?.value ?? '',
              msa_name: val?.label ?? ''
            });
          }}
          error={valMessages['msa_id'] ?? ''}
          readOnly={readOnly}
          required
        />
      )}

      <UnitText
        label={fieldLabel.brokerRepFirmLicenseNumber}
        name="broker_rep_firm_license_number"
        value={marketPreference.broker_rep_firm_license_number ?? ''}
        onChange={handleChange}
        readOnly={readOnly}
      />

      {readOnly === true ? (
        <UnitRead
          label={fieldLabel.account}
          value={
            <>
              <RouteLink
                url={`${`/accounts/${marketPreference.account_id}/view`}`}
                name={marketPreference.account_name || '-'}
                target={true}
              />
            </>
          }
        />
      ) : (
        <UnitAccount
          value={{
            label: marketPreference.account_name ?? '',
            value: marketPreference.account_id
          }}
          multiple={false}
          onChange={(val: OptionType) => {
            handleChange({
              account_id: val?.value ?? '',
              account_name: val?.label ?? ''
            });
          }}
          error={valMessages['account_id'] ?? ''}
          readOnly={readOnly}
          required
        />
      )}

      <UnitText
        label={fieldLabel.brokerRepMlsId}
        name="broker_rep_mlsid"
        value={marketPreference.broker_rep_mlsid ?? ''}
        onChange={handleChange}
        readOnly={readOnly}
      />

      <AutoCompleteUnit
        options={getObjectEntriesAsArray(opportunityStatus)}
        values={marketPreference.sqs_status_trigger_text_display || []}
        multiple={true}
        label="SQS Status Trigger"
        id="sqs_status_trigger"
        filterSelectedOptions={true}
        readOnly={readOnly}
        onChange={(val: ObjectType) => {
          handleChange({
            sqs_status_trigger_text_display: val.target.value
          });
        }}
      />

      <UnitText
        label={fieldLabel.brokerRepAgentLicenseNumber}
        name="broker_rep_agent_license_number"
        value={marketPreference.broker_rep_agent_license_number ?? ''}
        onChange={handleChange}
        readOnly={readOnly}
      />

      <UnitEmpty />

      <UnitText
        label={fieldLabel.brokerRepAgentEmail}
        name="broker_rep_agent_email"
        value={marketPreference.broker_rep_agent_email ?? ''}
        onChange={handleChange}
        readOnly={readOnly}
      />

      <UnitText
        label={fieldLabel.preCloseNotificationDays}
        name="pre_close_notification_days"
        value={marketPreference.pre_close_notification_days ?? ''}
        onChange={handleChange}
        readOnly={readOnly}
      />

      <UnitText
        label={fieldLabel.preCloseNotificationToRecipient}
        name="pre_close_notification_to_recipient"
        value={marketPreference.pre_close_notification_to_recipient ?? ''}
        onChange={handleChange}
        readOnly={readOnly}
      />

      <UnitText
        label={fieldLabel.preCloseNotificationCcRecipient}
        name="pre_close_notification_cc_recipient"
        value={marketPreference.pre_close_notification_cc_recipient ?? ''}
        onChange={handleChange}
        readOnly={readOnly}
      />

      <UnitSelect
        label={fieldLabel.crmStatus}
        name="crm_status"
        records={getObjectEntriesAsArray(crmStatusType)}
        value={marketPreference.crm_status ?? ''}
        onChange={handleChange}
        readOnly={readOnly}
      />

      <UnitRead
        label={fieldLabel.viewMarketOfferDefaults}
        value={
          !isEmpty(market_preference_id) ? (
            <>
              <RouteLink
                url={`/market-preferences/${market_preference_id}/view-market-offer-defaults`}
                name={marketPreference.name ?? ''}
                target={true}
              />
            </>
          ) : (
            <></>
          )
        }
      />

      <UnitSwitch
        value={marketPreference.enable_mp_wise_contract ?? 0}
        onChange={handleChange}
        name="enable_mp_wise_contract"
        label={fieldLabel.enableMpWiseContract}
        disabled={readOnly}
      />

      <UnitSwitch
        value={marketPreference.enable_generate_cgm_contract ?? 0}
        onChange={handleChange}
        name="enable_generate_cgm_contract"
        label={fieldLabel.enableGenerateCgmContract}
        disabled={readOnly}
      />

      <UnitSwitch
        value={marketPreference.enable_cgm_preview_contract ?? 0}
        onChange={handleChange}
        name="enable_cgm_preview_contract"
        label={fieldLabel.enableCgmPreviewContract}
        disabled={readOnly}
      />

      <UnitSwitch
        value={marketPreference.enable_cgm_multiple_generate_contract ?? 0}
        onChange={handleChange}
        name="enable_cgm_multiple_generate_contract"
        label={fieldLabel.enableCgmMultipleGenerateContract}
        disabled={readOnly}
      />
      <UnitSwitch
        value={marketPreference.pre_close_notification ?? 0}
        onChange={handleChange}
        name="pre_close_notification"
        label={fieldLabel.preCloseNotification}
        disabled={readOnly}
      />
    </FormContainer>
  );
};

export default RecordView;
