import { ObjectType } from 'types';
import { DashboardFilterEntity } from 'types/dashboard-type';

const dashboardInitialFilters: { [key: string]: DashboardFilterEntity } = {
  NEGOTIATOR_DASHBOARD_OFFER_MAKE_OFFER: {
    opportunity_status_c: [
      { value: 'offer_make_offer', label: 'Offer: Make Offer' }
    ],
    property_address_c: '',
    entera_opportunity_id: '',
    mls_c: '',
    street_name: '',
    mls_status_c: '',
    account_id: [],
    msa_id: [],
    seller_offer_response: '',
    offer_date_c_range: 'equal',
    offer_date_c: '',
    offer_date_to_c: '',
    active_primary_negotiator_user_id: []
  },
  NEGOTIATOR_DASHBOARD_SELLER_SENT_RECEIVED: {
    opportunity_status_c: [
      { value: 'offer_sent_to_seller', label: 'Offer: Sent to Seller' },
      { value: 'offer_seller_received', label: 'Offer: Seller Received' }
    ],
    property_address_c: '',
    entera_opportunity_id: '',
    mls_c: '',
    street_name: '',
    mls_status_c: '',
    account_id: [],
    msa_id: [],
    seller_offer_response: '',
    offer_date_c_range: 'equal',
    offer_date_c: '',
    offer_date_to_c: '',
    active_primary_negotiator_user_id: []
  },
  NEGOTIATOR_DASHBOARD_COUNTERED_OFFER: {
    opportunity_status_c: [
      { value: 'offer_seller_countered', label: 'Offer: Seller Countered' },
      { value: 'offer_counter_updated', label: 'Offer: Counter Updated' }
    ],
    property_address_c: '',
    entera_opportunity_id: '',
    mls_c: '',
    street_name: '',
    mls_status_c: '',
    account_id: [],
    msa_id: [],
    seller_offer_response: '',
    offer_date_c_range: 'equal',
    offer_date_c: '',
    offer_date_to_c: '',
    active_primary_negotiator_user_id: []
  },
  NEGOTIATOR_DASHBOARD_ACCEPTED_OFFER: {
    opportunity_status_c: [
      { value: 'offer_accepted', label: 'Offer: Accepted' },
      {
        value: 'offer_short_sale_offer_accepted',
        label: 'Offer: Short Sale Offer Accepted'
      }
    ],
    property_address_c: '',
    entera_opportunity_id: '',
    mls_c: '',
    street_name: '',
    mls_status_c: '',
    account_id: [],
    msa_id: [],
    seller_offer_response: '',
    offer_date_c_range: 'equal',
    offer_date_c: '',
    offer_date_to_c: '',
    active_primary_negotiator_user_id: []
  },
  NEGOTIATOR_DASHBOARD_DD_ENDING: {
    opportunity_status_c: [
      { value: 'closing_diligence_period', label: 'Closing: Deligence Period' },
      {
        value: 'closing_diligence_amended',
        label: 'Closing: Diligence Amended'
      }
    ],
    property_address_c: '',
    entera_opportunity_id: '',
    mls_c: '',
    street_name: '',
    mls_status_c: '',
    account_id: [],
    msa_id: [],
    seller_offer_response: '',
    offer_date_c_range: 'equal',
    offer_date_c: '',
    offer_date_to_c: '',
    active_primary_negotiator_user_id: []
  },
  NEGOTIATOR_DASHBOARD_SALE_PENDING: {
    opportunity_status_c: [
      { value: 'closing_sale_pending', label: 'Closing: Sale Pending' },
      {
        value: 'closing_sale_pending_amended',
        label: 'Closing: Sale Pending Amended'
      },
      {
        value: 'closing_clear_to_close',
        label: 'Closing: Clear to Close'
      }
    ],
    property_address_c: '',
    entera_opportunity_id: '',
    mls_c: '',
    street_name: '',
    mls_status_c: '',
    account_id: [],
    msa_id: [],
    seller_offer_response: '',
    offer_date_c_range: 'equal',
    offer_date_c: '',
    offer_date_to_c: '',
    active_primary_negotiator_user_id: []
  },
  NEGOTIATOR_DASHBOARD_TERMINATION: {
    opportunity_status_c: [
      { value: 'closing_cancel_contract', label: 'Closing: Cancelled' },
      {
        value: 'closing_termination_pending',
        label: 'Closing: Termination Pending'
      }
    ],
    property_address_c: '',
    entera_opportunity_id: '',
    mls_c: '',
    street_name: '',
    mls_status_c: '',
    account_id: [],
    msa_id: [],
    seller_offer_response: '',
    offer_date_c_range: 'equal',
    offer_date_c: '',
    offer_date_to_c: '',
    active_primary_negotiator_user_id: []
  },
  CLOSING_DASHBOARD_DILIGENCE_PERIOD: {
    opportunity_status_c: [
      { value: 'closing_diligence_period', label: 'Closing: Deligence Period' },
      {
        value: 'closing_diligence_amended',
        label: 'Closing: Diligence Amended'
      }
    ],
    property_address_c: '',
    entera_opportunity_id: '',
    mls_c: '',
    street_name: '',
    mls_status_c: '',
    account_id: [],
    msa_id: [],
    seller_offer_response: '',
    offer_date_c_range: 'equal',
    offer_date_c: '',
    offer_date_to_c: ''
  },
  CLOSING_DASHBOARD_SALE_PENDING: {
    opportunity_status_c: [
      { value: 'closing_sale_pending', label: 'Closing: Sale Pending' },
      {
        value: 'closing_sale_pending_amended',
        label: 'Closing: Sale Pending Amended'
      }
    ],
    property_address_c: '',
    entera_opportunity_id: '',
    mls_c: '',
    street_name: '',
    mls_status_c: '',
    account_id: [],
    msa_id: [],
    seller_offer_response: '',
    offer_date_c_range: 'equal',
    offer_date_c: '',
    offer_date_to_c: ''
  },
  CLOSING_DASHBOARD_CLEAR_TO_CLOSE: {
    opportunity_status_c: [
      { value: 'closing_cancel_contract', label: 'Closing: Cancelled' },
      {
        value: 'closing_termination_pending',
        label: 'Closing: Termination Pending'
      }
    ],
    property_address_c: '',
    entera_opportunity_id: '',
    mls_c: '',
    street_name: '',
    mls_status_c: '',
    account_id: [],
    msa_id: [],
    seller_offer_response: '',
    offer_date_c_range: 'equal',
    offer_date_c: '',
    offer_date_to_c: ''
  },
  CLOSING_DASHBOARD_TERMINATIONS: {
    opportunity_status_c: [
      { value: 'closing_cancel_contract', label: 'Closing: Cancelled' },
      {
        value: 'closing_termination_pending',
        label: 'Closing: Termination Pending'
      }
    ],
    property_address_c: '',
    entera_opportunity_id: '',
    mls_c: '',
    street_name: '',
    mls_status_c: '',
    account_id: [],
    msa_id: [],
    seller_offer_response: '',
    offer_date_c_range: 'equal',
    offer_date_c: '',
    offer_date_to_c: '',
    active_primary_negotiator_user_id: []
  },
  CLOSING_DASHBOARD_CLOSED_PURCHASED: {
    opportunity_status_c: [
      { value: 'closed_purchased', label: 'Closed:  Purchased' }
    ],
    property_address_c: '',
    entera_opportunity_id: '',
    mls_c: '',
    street_name: '',
    mls_status_c: '',
    account_id: [],
    msa_id: [],
    seller_offer_response: '',
    offer_date_c_range: 'equal',
    offer_date_c: '',
    offer_date_to_c: ''
  },
  TRANSACTION_DASHBOARD_EARNEST_MONEY: {
    opportunity_status_c: [
      { value: 'closing_diligence_period', label: 'Closing: Diligence Period' }
    ],
    property_address_c: '',
    entera_opportunity_id: '',
    mls_c: '',
    street_name: '',
    mls_status_c: '',
    account_id: [],
    msa_id: [],
    seller_offer_response: '',
    offer_date_c_range: 'equal',
    offer_date_c: '',
    offer_date_to_c: ''
  },
  TRANSACTION_DASHBOARD_OPTION_FEE: {
    opportunity_status_c: [
      { value: 'closing_diligence_period', label: 'Closing: Diligence Period' }
    ],
    property_address_c: '',
    entera_opportunity_id: '',
    mls_c: '',
    street_name: '',
    mls_status_c: '',
    account_id: [],
    msa_id: [],
    seller_offer_response: '',
    offer_date_c_range: 'equal',
    offer_date_c: '',
    offer_date_to_c: ''
  },
  TRANSACTION_DASHBOARD_DD_ENDING: {
    opportunity_status_c: [
      { value: 'closing_diligence_amended', label: 'Closing: Diligence Amended' }
    ],
    property_address_c: '',
    entera_opportunity_id: '',
    mls_c: '',
    street_name: '',
    mls_status_c: '',
    account_id: [],
    msa_id: [],
    seller_offer_response: '',
    offer_date_c_range: 'equal',
    offer_date_c: '',
    offer_date_to_c: ''
  },
  TRANSACTION_DASHBOARD_REVIEW_CONTRACT: {
    opportunity_status_c: [
      { value: 'closing_diligence_period', label: 'Closing: Diligence Period' },
      { value: 'closing_diligence_amended', label: 'Closing: Diligence Amended' }
    ],
    property_address_c: '',
    entera_opportunity_id: '',
    mls_c: '',
    street_name: '',
    mls_status_c: '',
    account_id: [],
    msa_id: [],
    seller_offer_response: '',
    offer_date_c_range: 'equal',
    offer_date_c: '',
    offer_date_to_c: ''
  },
  TRANSACTION_DASHBOARD_FORCSTED_COE: {
    opportunity_status_c: [
      { value: 'closing_sale_pending', label: 'Closing: Sale Pending' },
      {
        value: 'closing_sale_pending_amended',
        label: 'Closing: Sale Pending Amended'
      }
    ],
    property_address_c: '',
    entera_opportunity_id: '',
    mls_c: '',
    street_name: '',
    mls_status_c: '',
    account_id: [],
    msa_id: [],
    seller_offer_response: '',
    offer_date_c_range: 'equal',
    offer_date_c: '',
    offer_date_to_c: ''
  },
  TRANSACTION_DASHBOARD_CLOSING_TODAY: {
    opportunity_status_c: [
      { value: 'closing_clear_to_close', label: 'Closing: Clear To Close' }
    ],
    property_address_c: '',
    entera_opportunity_id: '',
    mls_c: '',
    street_name: '',
    mls_status_c: '',
    account_id: [],
    msa_id: [],
    seller_offer_response: '',
    offer_date_c_range: 'equal',
    offer_date_c: '',
    offer_date_to_c: ''
  },
  TRANSACTION_DASHBOARD_TERMINATION_PENDING: {
    opportunity_status_c: [
      {
        value: 'closing_termination_pending',
        label: 'Closing: Termination Pending'
      }
    ],
    property_address_c: '',
    entera_opportunity_id: '',
    mls_c: '',
    street_name: '',
    mls_status_c: '',
    account_id: [],
    msa_id: [],
    seller_offer_response: '',
    offer_date_c_range: 'equal',
    offer_date_c: '',
    offer_date_to_c: ''
  }


};

export default dashboardInitialFilters;
