import { missingField } from 'assets/validation-template';
import { isEmpty } from 'helpers/misc-helper';
import { ObjectType } from 'types';

interface validatePdfTemplateErrorType {
  hasValidationError: boolean;
  errors: { [key: string]: string[] };
}

export const validatePdfTemplate = (
  data: ObjectType,
  market_preference_id: string | undefined
): validatePdfTemplateErrorType => {
  let errors: validatePdfTemplateErrorType['errors'] = {};

  let validation: validatePdfTemplateErrorType = {
    hasValidationError: false,
    errors: errors
  };

  if (isEmpty(data.name) && isEmpty(market_preference_id)) {
    validation = {
      hasValidationError: true,
      errors: { ...validation.errors, name: [missingField('Name')] }
    };
  }

  if (isEmpty(data.type) && isEmpty(market_preference_id)) {
    validation = {
      hasValidationError: true,
      errors: { ...validation.errors, type: [missingField('Type')] }
    };
  }

  if (isEmpty(data.state_c) && isEmpty(market_preference_id)) {
    validation = {
      hasValidationError: true,
      errors: { ...validation.errors, state_c: [missingField('State')] }
    };
  }

  if (isEmpty(data.category_id) && isEmpty(market_preference_id)) {
    validation = {
      hasValidationError: true,
      errors: {
        ...validation.errors,
        category_id: [missingField('Document Type')]
      }
    };
  }

  if (isEmpty(data.document_subtype) && isEmpty(market_preference_id)) {
    validation = {
      hasValidationError: true,
      errors: {
        ...validation.errors,
        document_subtype: [missingField('Document Sub Type')]
      }
    };
  }

  return validation;
};
