import { ChangeEvent } from 'types/common-types';
import { ErrorValidation } from 'types/error-types';
import { ProfileEntity } from 'types/user';
import { toastError, toastSuccess } from 'event/toast-event';
import { useEffect, useState } from 'react';
import { validateProfile } from 'validations/profile';
import CancelButton from 'components/form/button-cancel';
import initialProfile from 'state/profile/initial-profile';
import PaperBox from 'components/paper-box';
import PaperBoxContent from 'components/paper-box/paper-box-content';
import RecordView from '../record-view';
import SaveButton from 'components/form/button-save';
import StackRowWithDivider from 'components/stack/stack-row-with-divider';
import useRouteName from 'pages/route-outlet-context';
import userService from 'services/user-service';

const ProfileCreate = ({ routeTag }: { routeTag: string }) => {
  const [profile, setProfile] = useState<ProfileEntity>(initialProfile);
  const { setRouteName } = useRouteName();
  const [validation, setValidation] = useState<ErrorValidation>({});
  const [loading, setLoading] = useState<boolean>(false);

  const handleChange = (e: ChangeEvent) => {
    setProfile((prevProfile) => ({
      ...prevProfile,
      [e.target.name]: e.target.value
    }));
  };

  const loadUser = async () => {
    const result = await userService.getCurrentUser();

    if (result.isError) {
      toastError(result.errorMessage.message);
      return;
    }

    if (result.isSuccess) {
      setProfile(result.data);
    }
  };

  const handleSubmit = async () => {
    const { status, errors } = validateProfile(profile);
    setValidation(errors);
    if (!status) return;

    setLoading(true);
    const result = await userService.update(profile.id, profile);
    setLoading(false);

    if (result.isValidationError) {
      setValidation(result.validationMessage);
      return;
    }

    if (result.isError) {
      toastError(result.errorMessage.message);
      return;
    }

    if (result.isSuccess) {
      toastSuccess('Your profile Updated.');
    }
  };

  useEffect(() => {
    loadUser();
  }, []);

  useEffect(() => {
    setRouteName(routeTag);
  }, []);

  return (
    <>
      <PaperBox>
        <PaperBoxContent>
          <RecordView
            profile={profile}
            validation={validation}
            handleChange={handleChange}
          />

          <StackRowWithDivider>
            <SaveButton onClick={handleSubmit} disabled={loading} />
            <CancelButton />
          </StackRowWithDivider>
        </PaperBoxContent>
      </PaperBox>
    </>
  );
};
export default ProfileCreate;
