import fieldLabel from 'assets/constants/fieldLabel';
import leaseType from 'assets/constants/lease-type';
import leasedOwned from 'assets/constants/leased-owned';
import optionDaysType from 'assets/constants/option-days-type';
import InfoMessage from 'components/errors/info-component';
import SaveButton from 'components/form/button-save';
import FormContainer from 'components/form/container';
import UnitDate from 'components/form/unit-date';
import UnitDateTime from 'components/form/unit-date-time';
import UnitHeading from 'components/form/unit-heading';
import UnitItem from 'components/form/unit-item';
import UnitPriceFormatter from 'components/form/unit-price-formatter';
import UnitSelect from 'components/form/unit-select';
import UnitText from 'components/form/unit-text';
import PaperBox from 'components/paper-box';
import PaperBoxContent from 'components/paper-box/paper-box-content';
import PaperBoxFooter from 'components/paper-box/paper-box-footer';
import StackRow from 'components/stack/stack-row';
import StackRowWithDivider from 'components/stack/stack-row-with-divider';
import variableConfig from 'config/variable';
import { actionPerform } from 'event/action-event';
import { refreshOpportunity } from 'event/opportunity-event';
import { toastError } from 'event/toast-event';
import FieldChooser from 'features/dashboards/field-chooser/field-chooser';
import {
  FieldOptionsType,
  fieldOptions
} from 'features/dashboards/field-chooser/fields';
import DateUtility from 'helpers/date-helper';
import emptyFunction from 'helpers/empty-function-helper';
import federalHolidayHelper from 'helpers/federal-holiday-helper';
import { convertNumber, isEmpty } from 'helpers/misc-helper';
import getObjectEntriesAsArray from 'helpers/object-field-helper';
import opportunityHelper from 'helpers/opportunity-helper';
import { validateBrokerageAction } from 'helpers/validation/brokerage-action-helper';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import brokerageActionService from 'services/brokerage-action-service';
import { due_diligence_end_c } from 'services/validation/due_diligence_end_c';
import { initialCountOfferReceipt } from 'state/brokerage-actions/counter-offer-receipt';
import { ObjectType } from 'types';
import {
  ActionRecordViewPropTypes,
  CounterOfferReceiptEntity,
  CounterOfferReceiptPayloadEntity
} from 'types/brokerage-action-types';
import { InputChangeEvent } from 'types/common-types';
import { ErrorValidation } from 'types/error-types';

import { getVisibleFormFields } from './helper';

const RecordView = ({
  onClose = emptyFunction,
  opportunity,
  isModal = false
}: ActionRecordViewPropTypes) => {
  const navigate = useNavigate();

  const [validation, setValidation] = useState<ErrorValidation>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fields, setFields] = useState<FieldOptionsType>(fieldOptions);
  const [data, setData] = useState<CounterOfferReceiptEntity>(
    initialCountOfferReceipt
  );

  const handleChange = (e: InputChangeEvent): void => {
    setData(Object.assign({}, data, { [e.target.name]: e.target.value }));
  };

  const validateData = (): boolean => {
    const fieldToValidate = ['counter_offer_price_c', 'counter_offer'];

    const { isValid, errors } = validateBrokerageAction(data, fieldToValidate);

    setValidation(errors);
    return isValid;
  };

  const prepareRequestBody = (): CounterOfferReceiptPayloadEntity => {
    const selectedCategories = Object.values(fields)
      .filter((field) => field.checked)
      .map((field) => field.name);

    const visibleFormFields = getVisibleFormFields(fields);

    const requestBody: CounterOfferReceiptPayloadEntity = {
      selected_categories: selectedCategories,
      counter_offer_price_c: data.counter_offer_price_c,
      counter_offer: data.counter_offer,
      ...visibleFormFields.reduce((acc: ObjectType, field: string) => {
        acc[field] = data[field];
        return acc;
      }, {}),
      due_diligence_end_c: data.due_diligence_end_c,
      option_days_type_c: data.option_days_type_c
    };

    return requestBody;
  };

  const handleSubmit = async (): Promise<void> => {
    const isValid = validateData();

    if (!isValid) return;

    const requestBody: CounterOfferReceiptPayloadEntity = prepareRequestBody();

    setIsLoading(true);

    const result =
      await brokerageActionService.update<CounterOfferReceiptPayloadEntity>(
        opportunity.id,
        requestBody,
        'counter_offer_receipt'
      );

    setIsLoading(false);

    if (result.isValidationError) {
      setValidation(result.validationMessage);
      return;
    }

    if (result.isError) {
      toastError(result.errorMessage.message);
      return;
    }

    if (result.isSuccess) {
      if (isModal) {
        onClose();
        actionPerform.counter_offer();
      } else {
        navigate(`/opportunities/${opportunity.id}/view`);
        refreshOpportunity({});
      }
    }
  };

  const handleCheck = (e: InputChangeEvent): void => {
    setFields(() => {
      for (const field of Object.values(fields)) {
        if (field.name.includes(e.target.id!)) {
          field.checked = !field.checked;
        }
      }
      return { ...fields };
    });
  };

  const getValidDate = (date: string, field: string, label: string): string => {
    const { validDate, message } =
      federalHolidayHelper.rolloverDateIfFallsOnFederalHolidayAndGetMessage(
        date,
        label,
        field === 'close_date_c'
          ? true
          : opportunityHelper.isOptionDayTypeIsBusinessOrCalendarDaysNoWeekendClosings(
              data.option_days_type_c
            )
      );

    setValidation((prevValidation) => ({
      ...prevValidation,
      [`${field}_federal_holiday_validation_message`]: message
    }));

    return DateUtility.getFormattedDateString(validDate);
  };

  useEffect(() => {
    const newDueDiligenceEndValue = due_diligence_end_c.calculate(
      {
        ...data,
        contract_execution_date_c: opportunity.contract_execution_date_c
      },
      ''
    );

    const validDate = getValidDate(
      newDueDiligenceEndValue,
      'due_diligence_end_c',
      fieldLabel.dueDiligenceEndDate
    );

    setData((prevData) => ({
      ...prevData,
      due_diligence_end_c: DateUtility.addHours(
        moment(validDate),
        variableConfig.DUE_DILIGENCE_END_START_TIME
      )
    }));
  }, [data.option_days_type_c, data.option_period_days_c]);

  return (
    <>
      <PaperBox variantValue="elevation" sx={{ p: 0 }}>
        <PaperBoxContent
          sx={{
            height: 'calc(100vh - 45vh)',
            overflowY: 'auto',
            p: 2
          }}
        >
          <FormContainer>
            <UnitItem grid={{ xs: 12, sm: 3 }} p={2}>
              <FieldChooser data={fields} setFields={handleCheck} />
            </UnitItem>

            <UnitPriceFormatter
              name="initial_offer_price"
              label={fieldLabel.initialOfferPrice}
              value={convertNumber(opportunity.initial_offer_price_c)}
              grid={{ xs: 12, sm: 2 }}
              onChange={emptyFunction}
              readOnly={true}
              prefix="$"
            />

            <UnitPriceFormatter
              name="offer_price_c"
              label={fieldLabel.offerPrice}
              value={convertNumber(opportunity.offer_price_c)}
              grid={{ xs: 12, sm: 2 }}
              onChange={emptyFunction}
              readOnly={true}
              prefix="$"
            />

            <UnitPriceFormatter
              name="max_offer_price_c"
              label={fieldLabel.maxOfferPrice}
              value={convertNumber(opportunity.max_offer_price_c)}
              grid={{ xs: 12, sm: 2 }}
              onChange={emptyFunction}
              readOnly={true}
              prefix="$"
            />

            <UnitPriceFormatter
              name="list_price_c"
              label={fieldLabel.listPrice}
              value={convertNumber(opportunity.list_price_c)}
              grid={{ xs: 12, sm: 2 }}
              onChange={emptyFunction}
              readOnly={true}
              prefix="$"
            />

            <UnitItem grid={{ xs: 12, sm: 12 }} p={2}>
              <StackRowWithDivider />
            </UnitItem>

            {fields.price && fields.price.checked && (
              <>
                <StackRowWithDivider />
                <UnitHeading title="Price" />

                <UnitPriceFormatter
                  label={`${fieldLabel.counterOfferPrice}`}
                  name="counter_offer_price_c"
                  value={data.counter_offer_price_c ?? ''}
                  onChange={handleChange}
                  error={validation['counter_offer_price_c'] ?? ''}
                  required
                />

                <UnitText
                  label={fieldLabel.counterOffer}
                  name="counter_offer"
                  value={data.counter_offer ?? ''}
                  onChange={handleChange}
                  error={validation['counter_offer'] ?? ''}
                  multiline
                  rows={3}
                  required
                />
              </>
            )}
            {fields.diligence_period && fields.diligence_period.checked && (
              <>
                <UnitHeading title="Dilligence Period" />

                <UnitText
                  label={fieldLabel.optionPeriodDays}
                  name="option_period_days_c"
                  value={data.option_period_days_c ?? ''}
                  onChange={handleChange}
                  error={validation['option_period_days_c'] ?? ''}
                  grid={{ xs: 12, sm: 4 }}
                />

                <UnitSelect
                  name="option_days_type_c"
                  label={fieldLabel.optionDaysType}
                  records={getObjectEntriesAsArray(optionDaysType)}
                  value={data.option_days_type_c ?? ''}
                  onChange={handleChange}
                  error={validation['option_days_type_c'] ?? ''}
                  grid={{ xs: 12, sm: 4 }}
                />

                <UnitItem grid={{ xs: 12, sm: 4 }}>
                  <UnitDateTime
                    label={fieldLabel.dueDiligenceEndDate}
                    name="due_diligence_end_c"
                    value={data.due_diligence_end_c || ''}
                    onChange={emptyFunction}
                    error={validation['due_diligence_end_c'] ?? ''}
                    grid={{ sm: 12, xs: 12 }}
                    disabled
                  />
                  {!isEmpty(
                    validation.due_diligence_end_c_federal_holiday_validation_message
                  ) && (
                    <InfoMessage
                      message={
                        validation.due_diligence_end_c_federal_holiday_validation_message ??
                        ''
                      }
                    />
                  )}
                </UnitItem>

                <UnitPriceFormatter
                  label={fieldLabel.optionAmount}
                  name="option_amount_c"
                  value={data.option_amount_c ?? ''}
                  onChange={handleChange}
                  error={validation['option_amount_c'] ?? ''}
                  grid={{ xs: 12, sm: 4 }}
                />
              </>
            )}
            {fields.exclusions_inclusions &&
              fields.exclusions_inclusions.checked && (
                <>
                  <UnitHeading title="Exclusions/Inclusions" />

                  <UnitText
                    label={fieldLabel.exclusion}
                    name="exclusion"
                    value={data.exclusion ?? ''}
                    onChange={handleChange}
                    error={validation['exclusion'] ?? ''}
                    multiline
                    rows={4}
                  />

                  <UnitText
                    label={fieldLabel.inclusion}
                    name="inclusion"
                    value={data.inclusion ?? ''}
                    onChange={handleChange}
                    error={validation['inclusion'] ?? ''}
                    multiline
                    rows={4}
                  />
                </>
              )}
            {fields.earnest_money && fields.earnest_money.checked && (
              <>
                <UnitHeading title="Earnest Money" />

                <UnitPriceFormatter
                  label={fieldLabel.earnestAmount}
                  name="earnest_amount_c"
                  value={data.earnest_amount_c ?? ''}
                  onChange={handleChange}
                  error={validation['earnest_amount_c'] ?? ''}
                  grid={{ xs: 12, sm: 4 }}
                />
              </>
            )}
            {fields.close_date && fields.close_date.checked && (
              <>
                <UnitHeading title="Close Date" />
                <UnitItem grid={{ xs: 12, sm: 4 }}>
                  <UnitDate
                    label={fieldLabel.closeDate}
                    name="close_date_c"
                    value={data.close_date_c ?? ''}
                    onChange={(value: string) => {
                      const validDate = getValidDate(
                        value,
                        'close_date_c',
                        fieldLabel.closeDate
                      );
                      setData((prevData) => ({
                        ...prevData,
                        close_date_c: validDate
                      }));
                    }}
                    error={validation['close_date_c'] ?? ''}
                    grid={{ xs: 12, sm: 12 }}
                  />
                  {!isEmpty(
                    validation.close_date_c_federal_holiday_validation_message
                  ) && (
                    <InfoMessage
                      message={
                        validation.close_date_c_federal_holiday_validation_message ??
                        ''
                      }
                    />
                  )}
                </UnitItem>
              </>
            )}
            {fields.post_occupancy && fields.post_occupancy.checked && (
              <>
                <UnitHeading title="Post Occupancy" />

                <UnitDate
                  label={fieldLabel.leaseEndDate}
                  name="lease_end_date"
                  value={data.lease_end_date ?? ''}
                  onChange={(e: string) =>
                    handleChange({
                      target: { name: 'lease_end_date', value: e }
                    })
                  }
                  error={validation['lease_end_date'] ?? ''}
                  grid={{ xs: 12, sm: 3 }}
                />

                <UnitPriceFormatter
                  label={fieldLabel.depositAmount}
                  name="deposit_amount"
                  value={data.deposit_amount ?? ''}
                  onChange={handleChange}
                  error={validation['deposit_amount'] ?? ''}
                  grid={{ xs: 12, sm: 3 }}
                />

                <UnitPriceFormatter
                  label={fieldLabel.rentalAmount}
                  name="rental_amount"
                  value={data.rental_amount ?? ''}
                  onChange={handleChange}
                  error={validation['rental_amount'] ?? ''}
                  grid={{ xs: 12, sm: 3 }}
                />

                <UnitSelect
                  name="lease_type"
                  label={fieldLabel.leaseType}
                  records={getObjectEntriesAsArray(leaseType)}
                  value={data.lease_type ?? ''}
                  onChange={handleChange}
                  error={validation['lease_type'] ?? ''}
                  grid={{ xs: 12, sm: 3 }}
                />
              </>
            )}
            {fields.special_provisions && fields.special_provisions.checked && (
              <>
                <UnitHeading title="Special Provisions" />

                <UnitText
                  label={fieldLabel.additionalTermsComments}
                  name="additional_terms_comments_c"
                  value={data.additional_terms_comments_c ?? ''}
                  onChange={handleChange}
                  error={validation['additional_terms_comments_c'] ?? ''}
                  multiline
                  rows={10}
                  grid={{ xs: 12, sm: 12 }}
                />
              </>
            )}
            {fields.solar_panels && fields.solar_panels.checked && (
              <>
                <UnitHeading title="Solar Panels" />

                <UnitSelect
                  name="solar_panel_leased_or_owned"
                  label={fieldLabel.leaseOwned}
                  records={getObjectEntriesAsArray(leasedOwned)}
                  value={data.solar_panel_leased_or_owned ?? ''}
                  onChange={handleChange}
                  error={validation['solar_panel_leased_or_owned'] ?? ''}
                  grid={{ xs: 12, sm: 4 }}
                />

                <UnitPriceFormatter
                  label={fieldLabel.payoffAmount}
                  name="solar_panel_payoff_amount"
                  value={data.solar_panel_payoff_amount ?? ''}
                  onChange={handleChange}
                  error={validation['solar_panel_payoff_amount'] ?? ''}
                  grid={{ xs: 12, sm: 4 }}
                />

                <UnitDate
                  label={fieldLabel.leaseDateExpiration}
                  name="solar_panel_lease_date_expiration"
                  value={data.solar_panel_lease_date_expiration ?? ''}
                  onChange={(e: string) =>
                    handleChange({
                      target: {
                        name: 'solar_panel_lease_date_expiration',
                        value: e
                      }
                    })
                  }
                  error={validation['solar_panel_lease_date_expiration'] ?? ''}
                  grid={{ xs: 12, sm: 4 }}
                />
              </>
            )}
            {fields.other && fields.other.checked && (
              <>
                <UnitHeading title="Other Counter Offer Terms" />

                <UnitText
                  label={fieldLabel.otherCounterOfferTerms}
                  name="other_counter_offer_terms"
                  value={data.other_counter_offer_terms ?? ''}
                  onChange={handleChange}
                  error={validation['other_counter_offer_terms'] ?? ''}
                  multiline
                  rows={3}
                  grid={{ xs: 12, sm: 6 }}
                />
              </>
            )}
          </FormContainer>
        </PaperBoxContent>
        <PaperBoxFooter>
          <StackRow sx={{ pt: 0, pr: 0, pb: 0, pl: 0 }}>
            <SaveButton onClick={handleSubmit} disabled={isLoading} />
          </StackRow>
        </PaperBoxFooter>
      </PaperBox>
    </>
  );
};
export default RecordView;
